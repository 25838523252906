import React, { useState } from 'react';

import Header from "./functionalComponents/Header";
import Main from "./functionalComponents/Main";
import Footer from "./functionalComponents/Footer";
import PopupNewsletter from './functionalComponents/PopupNewsletter';

function App() {

  const [loading, setLoading] = React.useState(true);
  const [displayedArticles, setDisplayedArticles] = useState([]);
  const [author, setAuthor] = useState('');
  const [toggleHomepage, setToggleHomepage] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 350)
  }, [])



  const handleChildStateChange = (childState) => {
    setDisplayedArticles(childState);
  };



  const triggerHomepageChange = () => {
    if (toggleHomepage === '') {
      setToggleHomepage('trigger');
    }
  };



  function handleAuthorSorting (author) {
    setAuthor(author);
  }



  function toggleNewsletterPopup () {
    setIsPopupVisible(!isPopupVisible);
  }



  return (
    <div id="nea-app">
      <div className={loading ? 'cloak cloak--active' : 'cloak'}></div>
      <Header 
        author={author} 
        setDisplayedArticles={handleChildStateChange} 
        triggerHomepageChangeApp={triggerHomepageChange}
        toggleNewsletterPopup={toggleNewsletterPopup}
      />
      <Main 
        articles={displayedArticles} 
        handleAuthorSorting={handleAuthorSorting} 
        toggleHomepage={toggleHomepage}
        toggleNewsletterPopup={toggleNewsletterPopup}
      />
      <Footer />
      <PopupNewsletter isPopupVisible={isPopupVisible} toggleNewsletterPopup={toggleNewsletterPopup} />
    </div>
  );
};

export default App;