import axios from 'axios';
import React from 'react';
import Select from "../functionalComponents/Select";
import { useNavigate } from "react-router-dom";

export default function AssessArticlePage () {
    // Utilities
    const [articleTags, setArticleTags] = React.useState([]);
    const [unassessedArticles, setUnassessedArticles] = React.useState([]);
    const [authors, setAuthors] = React.useState([]);

    const [selectedArticle, setSelectedArticle] = React.useState('');
    const [selectedAuthor, setSelectedAuthor] = React.useState('');
    const [selectedTags, setSelectedTags] = React.useState([]);
    const [idToRemove, setIdToRemove] = React.useState("");
    const [idToEdit, setIdToEdit] = React.useState('');

    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    const [mode, setMode] = React.useState('ADD');
    // 

    const token = localStorage.getItem('token');
    const navigate = useNavigate();


    // Article to be submitted to the backend
    const [newArticle, setNewArticle] = React.useState({
        "id": "",
        "Person": "",
        "authorId": "",
        "Title": "",
        "Abstract": "",
        "Story": "",
        "Img": "",
        "Tags": [],
        "Views": 0,
        "Likes": 0,
        "Frontpage": false,
        "Assessed": false,
        "Anon": true,
        "Contact": "",
        "Date": "",
    });
    // 

    async function testAuth() {
        try {
            axios.post('https://lumapi.neahub.co.uk/admin', {
                headers: {
                    "Authorization": token,
                },
            })
                .then((response) => {
                    console.log(response);
                    // Handle the response
                })
                .catch((error) => {
                    console.log('ACCESS DENIED. User not logged in.');
                    navigate("/");
                    // Handle errors
                });

        } catch (error) {
            console.log(error)
            navigate("/");
        }
    }

    React.useEffect(() => {
        testAuth()
        fetchUnassessedArticles();
        fetchArticleTags();
        fetchAuthors();

        const today = new Date();
        formatDateToISOStrin(today);

        setLoading(false);
    }, [])



    function formatDateToISOStrin(date) {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

        setNewArticle({...newArticle, Date: formattedDate})
    }



    async function fetchUnassessedArticles () {
        console.log("[fetchArticles]")

        try {
            const response = await axios.post('https://lumapi.neahub.co.uk/admin/unassessed/article', {
                headers: {
                    "Authorization": token,
                },
            });
            const articles = await response.data;
            console.log(articles);
            setUnassessedArticles(articles);
        } catch (err) {
            setError("Unable to fetch unassessed articles.");
        }
    }



    async function fetchArticleTags () {
        console.log("[fetchArticleTags]")

        try {
            const response = await axios.post('https://lumapi.neahub.co.uk/functions/tags', {
                headers: {
                    "Authorization": token,
                },
            });
            const tags = await response.data.Tags;
            setArticleTags(tags);
        } catch {
            setError("Unable to fetch article tags");
        }
    }



    async function fetchAuthors() {
        console.log("[fetchAuthors]");

        try{
            const authors = await axios.post('https://lumapi.neahub.co.uk/admin/article/authors', {
                headers: {
                    "Authorization": token,
                },
            })
            setAuthors(authors.data);
            console.log(authors.data, "authors.data");

            if (authors.data === null){
                setError("Unable to fetch authors");
            }
            // const idList = authors.data.map(object => object.Author)
        } catch {
            setError("Unable to fetch authors");
        }
    }



    async function findArticle(articleId="") {
        let articleFound = false;
        console.log("[findArticle]");
        try {
            let article;

            if (articleId === "") {
                article = await axios.post(`https://lumapi.neahub.co.uk/admin/article/${idToEdit}`, {
                    headers: {
                        "Authorization": token,
                    },
                });
            } else {
                article = await axios.post(`https://lumapi.neahub.co.uk/admin/article/${articleId}`, {
                    headers: {
                        "Authorization": token,
                    },
                });
            }

            console.log(article.data)

            if (article.data !== null) {
                // console.log('Found Article:', article.data)
                setNewArticle(article.data, "article.data")

                if (article.Author == null) {
                    handleFetchedArticleAuthor({ "Author": "Anonymous", "Img": "https://i.imgur.com/JKZEBC4.jpg", "Description": "" });
                } else {
                    handleFetchedArticleAuthor(article.data.Author);
                }

                setError("");
                articleFound = true;
                return;
            }
        } catch (error) {
            setError(error);
        }

        if (!articleFound){
            setError(`Unable to find an article with the id: "${idToEdit}"`);
            return;
        }
    }



    function handleFetchedArticleAuthor(fetchedAuthor) {
        setSelectedAuthor(fetchedAuthor);
    }



    async function selectArticle(e) {
        console.log("[selectArticle]")

        const articleName = e.target.value;
        unassessedArticles.forEach((article) => {
            if (article.Title === articleName){
                console.log(article, "article");
                setSelectedArticle(article.Title);
                findArticle(article.id);
            }
        })
    }



    function selectAuthor(e){
        console.log("[selectAuthor]")
        const authorName = e.target.value;

        authors.forEach(author => {
            if (authorName === author.Author) {
                setNewArticle({
                    ...newArticle,
                    Person: author.Author,
                    authorId: author.id,
                    Author: {
                        ...author
                    }
                })
                setSelectedAuthor(author);
            }
        })
    }



    function selectTags(e){
        setSelectedTags([...selectedTags, e.target.value]);
        setNewArticle({...newArticle, Tags: [...selectedTags, e.target.value]});
    }



    function removeTags(tagToRemove){
        const newTags = selectedTags.filter(tag => tag !== tagToRemove);
        setSelectedTags(newTags);
        setNewArticle({...newArticle, Tags: newTags});
    }



    async function deleteArticle() {
        try {
            await axios.post(`https://lumapi.neahub.co.uk/admin/delarticle/${idToRemove}`, {
                headers: {
                    "Authorization": token,
                },
                body: {"Status":"Deleting"}
            });
        } catch (err) {
            setError(err);
        }
    }

    async function submitArticle() {
        try {
            setLoading(true);
            await axios.post(`https://lumapi.neahub.co.uk/admin/article`, {
                headers: {
                    "Authorization": token,
                },
                body: newArticle
            })
            .then(() => {
                setLoading(false);
                setSuccess(true);
            });
        } catch (err) {
            setError(err);
        }
    }




    return (
        <div className="page-content page-content--assess-article">
            <div className="container">

                <div className="deleteArticle-container">
                    <h1>Remove an article</h1>

                    <div className="form-group">
                        <span>Id of the article that you want removed.</span>
                        <input 
                            type="text" 
                            placeholder="ID"
                            value={idToRemove} 
                            onChange={(e) => setIdToRemove(e.target.value)} 
                        />
                    </div>

                    {
                        error.length > 0
                        &&
                        <div className="summary-error">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8 15.6H13.2V18H10.8V15.6ZM10.8 6H13.2V13.2H10.8V6ZM11.988 0C5.364 0 0 5.376 0 12C0 18.624 5.364 24 11.988 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 11.988 0ZM12 21.6C6.696 21.6 2.4 17.304 2.4 12C2.4 6.696 6.696 2.4 12 2.4C17.304 2.4 21.6 6.696 21.6 12C21.6 17.304 17.304 21.6 12 21.6Z" fill="#D53636"></path></svg>
                            <span>{error}</span>
                        </div>
                    }

                    <button type="button" className="btn btn--black" onClick={deleteArticle}>
                        <span>Remove Article</span>
                    </button>
                </div>
                
                <div className="assessArticle-container">

                    <h1>ADD / EDIT an article</h1>

                    <div className="form-group form-group--mode">
                        <button type="button" className="btn btn--transparent" onClick={() => setMode('ADD')}>
                            <span>Add an article</span>
                        </button>
                        <button type="button" className="btn btn--transparent" onClick={() => setMode('EDIT')}>
                            <span>Edit an article</span>
                        </button>
                    </div>

                    {
                        mode === 'ADD'
                        ?
                        <div className="form-group form-group--assessArticle">
                            <span>Select an article to assess</span>
                            <select 
                                value={selectedArticle} 
                                onChange={(e) => selectArticle(e)}
                            >
                                <option disabled value="">Select an unassessed article</option>
                                {
                                    unassessedArticles.map((article) => (
                                        <option value={article.Title} key={article.id}>{article.Title}</option>
                                    ))
                                }
                            </select>
                            <p>
                                It is optional to select an unassessed article. If you would like to add a new article,
                                you should leave the input above empty.
                            </p>
                        </div>
                        :
                        <div className="form-group form-group--findArticle">
                            <span>Write the id of the article you would like to edit.</span>
                            <input type="text" placeholder="Article ID" onChange={(e) => setIdToEdit(e.target.value)} />
                            <button className="btn btn--black" type="button" onClick={() => findArticle(idToEdit)}>
                                <span>Find Article</span>
                            </button>
                        </div>
                    }

                    <div className="form-group">
                        <span>Select Author</span>
                        <select
                            value={selectedAuthor.Author} 
                            onChange={(e) => selectAuthor(e)}
                        >
                            <option disabled value="">Select an Author</option>
                            {
                                authors.map((author) => (
                                    <option value={author.Author} key={author.id}>{author.Author}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="form-group">
                        <span>Email</span>
                        <input 
                            type="email" 
                            placeholder="Email"
                            value={newArticle.Contact} 
                            onChange={(e) => setNewArticle({...newArticle, Contact: e.target.value})} 
                        />
                    </div>

                    <div className="form-group">
                        <span>Title</span>
                        <input 
                            type="input" 
                            placeholder="Title"
                            value={newArticle.Title} 
                            onChange={(e) => setNewArticle({...newArticle, Title: e.target.value})} 
                        />
                    </div>

                    <div className="form-group">
                        <span>Abstract</span>
                        <textarea
                            value={newArticle.Abstract}
                            placeholder="Abstract"
                            onChange={(e) => setNewArticle({...newArticle, Abstract: e.target.value})}
                        ></textarea>
                    </div>

                    <div className="form-group">
                        <span>Story</span>
                        <textarea
                            value={newArticle.Story}
                            placeholder="Story"
                            onChange={(e) => setNewArticle({...newArticle, Story: e.target.value})}
                        ></textarea>
                    </div>

                    <div className="form-group">
                        <span>Article Tags</span>
                        <select
                            onChange={(e) => selectTags(e)}
                        >
                            <option disabled value="">Select Tags</option>
                            {
                                articleTags.map((tag, index) => (
                                    <option value={tag} key={index}>{tag}</option>
                                ))
                            }
                        </select>
                        <div className="form-group__select-additional">
                            <div className="select-additional__category">
                            {
                                newArticle.Tags
                                &&
                                newArticle.Tags.map((cat, index) => (
                                    <button 
                                        onClick={() => removeTags(cat)} 
                                        className="btn btn--black select-additional__category-btn" key={index}
                                    >
                                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.66658 1.77337L8.72658 0.833374L4.99992 4.56004L1.27325 0.833374L0.333252 1.77337L4.05992 5.50004L0.333252 9.22671L1.27325 10.1667L4.99992 6.44004L8.72658 10.1667L9.66658 9.22671L5.93992 5.50004L9.66658 1.77337Z" fill="#F5F5F5"/>
                                        </svg>
                                        <span>{cat}</span>
                                    </button>
                                ))
                            }
                        </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <span>Image link</span>
                        <input 
                            type="text" 
                            placeholder="Image link" 
                            onChange={(e) => setNewArticle({...newArticle, Img: e.target.value})}
                            value={newArticle.Img}
                        />
                    </div>

                    <div className="form-group">
                        <span>Additional Options</span>

                        <div className="form-group__buttonSubgroup">
                            <button 
                                type="button" 
                                className="btn btn--transparent" 
                                onClick={() => setNewArticle({...newArticle, Anon: !newArticle.Anon})}
                            >
                                <span>Anonymous</span>
                            </button>
                            <span>{newArticle.Anon ? 'Yes' : 'No'}</span>
                        </div>

                        <div className="form-group__buttonSubgroup">
                            <button 
                                type="button" 
                                className="btn btn--transparent" 
                                onClick={() => setNewArticle({...newArticle, Frontpage: !newArticle.Frontpage})}
                            >
                                <span>Frontpage</span>
                            </button>
                            <span>{newArticle.Frontpage ? 'Yes' : 'No'}</span>
                        </div>

                    </div>

                    {
                        error.length > 0
                        &&
                        <div className="summary-error">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8 15.6H13.2V18H10.8V15.6ZM10.8 6H13.2V13.2H10.8V6ZM11.988 0C5.364 0 0 5.376 0 12C0 18.624 5.364 24 11.988 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 11.988 0ZM12 21.6C6.696 21.6 2.4 17.304 2.4 12C2.4 6.696 6.696 2.4 12 2.4C17.304 2.4 21.6 6.696 21.6 12C21.6 17.304 17.304 21.6 12 21.6Z" fill="#D53636"></path></svg>
                            <span>{error}</span>
                        </div>
                    }

                    <div className="form-group">
                        <button className="btn btn--black" type="button" onClick={() => console.log(newArticle)}>
                            <span>Console log newArticle</span>
                        </button>
                    </div>

                    {
                        (success && error.length === 0)
                        &&
                        <div className="successful-message">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-bold</title><path d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" /></svg>
                            <span>Article submitted successfully!</span>
                        </div>
                    }

                    <div className="form-group form-group__assess-buttons">
                        <div className="form-group__left">
                            <button 
                                type="button" 
                                className="btn btn--assess" 
                                onClick={() => setNewArticle({...newArticle, Assessed: !newArticle.Assessed})}
                            >
                                <span>Confirm</span>
                            </button>
                        </div>
                        <div className="form-group__right">
                            <button onClick={submitArticle} type="button" disabled={!newArticle.Assessed} className="btn btn--black">
                                <span>Submit</span>
                            </button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}


// EXAMPLE ARTICLE OBJECT
// {
//     "id": "70249c12-c175-4098-bca3-ad71a79ffe82",
//     "Person": "Someone",
//     "authorId": "2eba58ba-ecc8-493e-a7d7-4f721f2304b0",
//     "Title": "Test Article",
//     "Abstract": "PLACEHOLDER",
//     "Story": "PLACEHOLDER",
//     "Img": "",
//     "Tags": [
//         "TAG1",
//         "TAG2"
//     ],
//     "Views": 0,
//     "Likes": 0,
//     "Frontpage": false,
//     "Assessed": false,
//     "Anon": true,
//     "Contact": "PLACEHOLDER",
//     "Date": "2023-08-12T15:21:39.031Z",
//     "Author": {
//         "id": "2eba58ba-ecc8-493e-a7d7-4f721f2304b0",
//         "Author": "Stefan",
//         "Img": "http://www.omgstory.com/img/2013/02/unbelievably-cute-laughing-monkeys-9-600x538.jpg",
//         "Description": "PLACEHOLDER"
//     }
// }
// 




















// export default function AssessArticlePage () {

//     const [tags, setTags] = React.useState([]);
//     const [authors, setAuthors] = React.useState([]);
//     const [articles, setArticles] = React.useState([]);
//     const [error, setError] = React.useState("");
//     const [confirm, setConfirm] = React.useState(false);
    
//     const [articleId, setArticleId] = React.useState("");

//     const [newArticle, setNewArticle] = React.useState({
//         Author: "",
//         Title: "",
//         Tags: [],
//         Abstract: "",
//         Story: "",
//         Anon: false,
//         Frontpage: false,
//         Img: "",
//         Date: new Date(),
//     });



//     React.useEffect(() => {
//         const requestOptions = {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         };
//         const url = "https://lumapi.neahub.co.uk/functions/tags";
        
//         fetch(url, requestOptions)
//             .then(response => response.json())
//             .then(data => {
//                 setTags(data.Tags);
//             })

//         fetchArticles();

//         getAuthors()
//     }, [])



//     async function fetchArticles () {
//         try {
//             const response = await axios.post('https://lumapi.neahub.co.uk/admin/unassessed/article');
//             const articles = await response.json();
//             setArticles(articles);
//             console.log(articles)
//         } catch (err) {
//             setError("Unable to fetch articles.");
//         }
//     }


//     async function findArticle() {

//         let articleFound = false;
//         try {
//             const article = await axios.post(`https://lumapi.neahub.co.uk/admin/article/${articleId}`);
//             if (article !== null) {

//                 console.log(article.data)
//                 setNewArticle(article.data)
//                 setError("");
//                 articleFound = true;
//                 return;
//             }
//         } catch (error) {
//             console.log(error)
//         }

//         // Search for an article with a matching id
//         // articles.forEach(article => {
//         //     if (article.id === articleId){

//         //         // CLEAR ALL INPUTS
//         //         setNewArticle(
//         //             {
//         //                 Author: "",
//         //                 Title: "",
//         //                 Tags: [],
//         //                 Abstract: "",
//         //                 Story: "",
//         //                 Anon: false,
//         //                 Frontpage: false,
//         //                 Img: "",
//         //                 Date: new Date(),
//         //             }
//         //         )
//         //         //

//         //         setNewArticle(article)
//         //         setError("");
//         //         articleFound = true;
//         //         console.log(article);
//         //         return;
//         //     }
//         // })

//         if (!articleFound){
//             setError(`Unable to find an article with the id: "${articleId}"`);
//             return;
//         }
//     }



//     function extractInfo(value) {
//         setNewArticle({...newArticle, Tags: value});
//     }



//     function handleImageChange (event) {
//         console.log(newArticle);

//         const userImage = event.target.files[0];
//         console.log(userImage);
//         if (userImage) {
//             const updatedArticle = { ...newArticle, Img: userImage };
//             setNewArticle(updatedArticle);
//         }
//     };


//     async function deleteArticle() {
//         setNewArticle({ ...newArticle });
//         try {
//             await axios.delete(`https://lumapi.neahub.co.uk/admin/article/${articleId}`);
//         } catch (error) {
//             console.log(error)
//         }
//     }


//     function submitArticle () {

//         // CHANGE THIS IF NEEDED
//         const url = "https://lumapi.neahub.co.uk/admin/article";
//         // 

//         const requestOptions = {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json'
//             },

//             body: JSON.stringify(newArticle),
//         };
//         fetch(url, requestOptions)
//             .then(response => response.json())
//             .then(data => {
//                 console.log(data);
//             })
//             .catch(setError("Unable to submit article!"))
//     }


//     async function getAuthors() {
//         const authors = await axios.post('https://lumapi.neahub.co.uk/admin/article/authors')
//         console.log(authors.data)
//         const idList = authors.data.map(object => object.Author)
//         setAuthors(idList)
//     }


//     return (
//         <div className="page-content page-content--assess-article">
//             <div className="container">

//                 <div className="form-group">
//                     <div className="form-group__info">
//                         <small className="form-group__small form-group__small--visible">Author</small>
//                     </div>
//                     <input 
//                         type="input" 
//                         placeholder="Author" 
//                         defaultValue={Object.keys(newArticle).length !== 0 ? newArticle.Author.Author : ''}
//                         onChange={e => setNewArticle({...newArticle, Author: e.target.value})}
//                     />
//                 </div>

//                 <Select
//                     selectOptions={newArticle.Author}
//                     placeholder="Authors"
//                     optionsList={authors}
//                     extractInfo={extractInfo}
//                 />

//                 <div className="form-group">
//                     <div className="form-group__info">
//                         <small className="form-group__small form-group__small--visible">Title</small>
//                     </div>
//                     <input 
//                         type="input" 
//                         placeholder="Title" 
//                         defaultValue={Object.keys(newArticle).length !== 0 ? newArticle.Title : ''}
//                         onChange={e => setNewArticle({...newArticle, Title: e.target.value})}
//                     />
//                 </div>

//                 <Select
//                     selectOptions={newArticle.Tags.length > 0 && newArticle.Tags}
//                     placeholder="Categories"
//                     optionsList={tags}
//                     extractInfo={extractInfo}
//                 />

//                 <div className="form-group">
//                     <div className="form-group__info">
//                         <small className="form-group__small form-group__small--visible">Short description</small>
//                     </div>
//                     <textarea 
//                         placeholder="Short description"
//                         defaultValue={Object.keys(newArticle).length !== 0 ? newArticle.Abstract : ''}
//                         onChange={e => setNewArticle({...newArticle, Abstract: e.target.value})}
//                     ></textarea>
//                 </div>

//                 <div className="form-group">
//                     <div className="form-group__info">
//                         <small className="form-group__small form-group__small--visible">Story</small>
//                     </div>
//                     <textarea 
//                         placeholder="Story"
//                         defaultValue={Object.keys(newArticle).length !== 0 ? newArticle.Story : ''}
//                         onChange={e => setNewArticle({...newArticle, Story: e.target.value})}
//                     ></textarea>
//                 </div>

//                 <div className="form-group form-group--ArticleImage">
//                     <div className="form-group__info">
//                         <small className="form-group__small form-group__small--visible">Image of the author</small>
//                     </div>
//                     <input 
//                         type="input" 
//                         placeholder="Image url"
//                         defaultValue={newArticle.Img.length > 0 ? newArticle.Img : ''} 
//                         onChange={(e) => setNewArticle({...newArticle, Img: e.target.value})}
//                     />
//                 </div>

//                 <div className="form-group form-group--ArticleOptions">
//                     <div className="form-group__options">
//                         <span>The Author is Anonymous:</span>
//                         <button onClick={() => setNewArticle({...newArticle, Anon: !newArticle.Anon})} type="button" className="btn btn--transparent">
//                             <span>{newArticle.Anon ? 'Yes' : 'No'}</span>
//                         </button>
//                     </div>
//                     <div className="form-group__options">
//                         <span>The Article can be seen on the Frontpage:</span>
//                         <button onClick={() => setNewArticle({...newArticle, Frontpage: !newArticle.Frontpage})} type="button" className="btn btn--transparent">
//                             <span>{newArticle.Frontpage ? 'Yes' : 'No'}</span>
//                         </button>
//                     </div>
//                 </div>

//                 <div className="form-group form-group__assess-buttons">
//                     <div className="FIX THIS PLEASE KRIS I BEG YOU !!!!!!!!!!!!!!">
//                         <button type="button" className="btn btn--delete" onClick={() => deleteArticle()}>
//                             <span>DELETE Article</span>
//                         </button>
//                     </div>
//                     <div className="form-group__left">
//                         <button type="button" className="btn btn--assess" onClick={() => setConfirm(!confirm)}>
//                             <span>Confirm</span>
//                         </button>
//                     </div>
//                     <div className="form-group__right">
//                         <button type="button" className="btn btn--black" onClick={submitArticle} disabled={!confirm}>
//                             <span>Submit</span>
//                         </button>
//                     </div>
//                 </div>

//                 {
//                     error !== ''
//                     &&
//                     <div className="summary-error">
//                         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                             <path d="M10.8 15.6H13.2V18H10.8V15.6ZM10.8 6H13.2V13.2H10.8V6ZM11.988 0C5.364 0 0 5.376 0 12C0 18.624 5.364 24 11.988 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 11.988 0ZM12 21.6C6.696 21.6 2.4 17.304 2.4 12C2.4 6.696 6.696 2.4 12 2.4C17.304 2.4 21.6 6.696 21.6 12C21.6 17.304 17.304 21.6 12 21.6Z" fill="#D53636"/>
//                         </svg>
//                         <span>{error}</span>
//                     </div>
//                 }

//                 <div className="form-group form-group--searchId">
//                     <input 
//                         className="form-group--searchId__input"
//                         placeholder="ID"
//                         value={articleId}
//                         onChange={(e) => setArticleId(e.target.value)}
//                     />
//                     <button onClick={findArticle} type="button" className="btn btn--black form-group--searchId__button">
//                         <span>Search</span>
//                     </button>
//                     <button type="button" className="btn btn--black" onClick={() => console.log(newArticle)}>
//                         <span>Console log newArticle</span>
//                     </button>
//                 </div>

//             </div>
//         </div>
//     )
// }