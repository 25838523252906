import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export default function AssessUserPage() {

    const [mode, setMode] = React.useState("add");
    const [assessed, setAssessed] = React.useState(false);
    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState(false);

    const [authors, setAuthors] = React.useState([]);
    const [selectedAuthor, setSelectedAuthor] = React.useState({
        id: "",
        Author: "",
        Img: "",
        Description: ""
    });

    const [newAuthor, setNewAuthor] = React.useState({
        Author: "",
        Description: "",
        Img: ""
    });



    useEffect(() => {
        fetchAuthors();
    }, [])



    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    async function testAuth() {
        try {
            axios.post('https://lumapi.neahub.co.uk/admin', {
                headers: {
                    "Authorization": token,
                },
            })
                .then((response) => {
                    console.log(response);
                    // Handle the response
                })
                .catch((error) => {
                    console.log('ACCESS DENIED. User not logged in.');
                    navigate("/");
                    // Handle errors
                });

        } catch (error) {
            console.log(error)
            navigate("/");
        }
    }

    // function handleSubmit() {

    //     // CHANGE
    //     const url = "https://lumapi.neahub.co.uk/admin/author";
    //     //

    //     const requestOptions = {
    //         method: 'PATCH',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             "Authorization": token,
    //         },
    //         body: JSON.stringify(newAuthor)
    //     };

    //     fetch(url, requestOptions)
    //         .then(response => response.json())
    //         .then(() => {
    //             setSuccess(true);
    //             setNewAuthor({
    //                 Author: "",
    //                 Description: "",
    //                 Img: ""
    //             })
    //         })
    //         .catch(() => setError('Unable to submit author information!'));
    // }

    async function fetchAuthors() {
        try{
            const authors = await axios.post('https://lumapi.neahub.co.uk/admin/author', {
                headers: {
                    "Authorization": token,
                },
            })
            setAuthors(authors.data);
            console.log(authors.data)

            if (authors.data === null){
                setError("Unable to fetch authors");
            }
            // const idList = authors.data.map(object => object.Author)
        } catch {
            setError("Unable to fetch authors");
        }

        setTimeout(() => setError(""), 5000);
    }


    function selectAuthor(e){
        const authorName = e.target.value;

        authors.forEach(author => {
            if (authorName === author.Author) setSelectedAuthor(author);
        })
    }


    async function handleSubmit() {
        let url = "https://lumapi.neahub.co.uk/admin/author";
        if (mode === "edit") url = "https://lumapi.neahub.co.uk/admin/author/update";

        try {
            if (mode === "add") {
                await axios.patch(url, {
                    headers: {
                        "Authorization": token,
                    },
                    body: newAuthor
                })
                .then(() => {
                    setError("");
                    setSuccess(true);
                    setNewAuthor({Author: "",Description: "",Img: ""});
                    fetchAuthors();
                    setTimeout(() => {
                        setSuccess(false);
                    }, 5000);
                });
            } else {
                await axios.post(url, {
                    headers: {
                        "Authorization": token,
                    },
                    body: selectedAuthor
                })
                .then(() => {
                    setError("");
                    setSuccess(true);
                    setSelectedAuthor({id: "",Author: "",Img: "",Description: ""});
                    fetchAuthors();
                    setTimeout(() => {
                        setSuccess(false);
                    }, 5000);
                });
            }
        } catch (err) {
            console.log(err);
            setError(err.message);
            setTimeout(() => {
                setError("");
            }, 5000)
        }
    }



    function handleConfirmation() {
        if (mode === "add") {
            if (newAuthor.Author !== '' && newAuthor.Description !== '' && newAuthor.Img !== '') {
                setAssessed(true);
                setError('');
            } else {
                setError('One or more fields are empty!');
            }
        } else {
            if (selectedAuthor.Author !== '' && selectedAuthor.Description !== '' && selectedAuthor.id !== '') {
                setAssessed(true);
                setError('');
            } else {
                setError('One or more fields are empty!');
            }
        }
        setTimeout(() => setError(""), 5000);
    }

    React.useEffect(() => {
        testAuth()
    }, [])

    return (
        <div className="page-content page-content--assess-user">
            <div className="container">

                <div className="__modeButtons">
                    <button type="button" className={mode === "add" ? "btn btn--mode btn--black" : "btn btn--mode"} onClick={() => setMode("add")}>
                        <span>Add user</span>
                    </button>
                    <button type="button" className={mode === "edit" ? "btn btn--mode btn--black" : "btn btn--mode"} onClick={() => setMode("edit")}>
                        <span>Edit user</span>
                    </button>
                </div>

                {
                    mode === "edit"
                    &&
                    <>
                    <h1>Edit user</h1>
                    <div className="form-group">
                        <div className="form-group__info">
                            <small className="form-group__small form-group__small--visible">Select Author</small>
                        </div>
                        <select
                            value={selectedAuthor.Author} 
                            onChange={(e) => selectAuthor(e)}
                        >
                            <option disabled value="">Select an Author</option>
                            {
                                authors.map((author) => (
                                    <option value={author.Author} key={author.id}>{author.Author}</option>
                                ))
                            }
                        </select>
                    </div>
                    </>
                }

                {
                    (mode === "edit" && selectedAuthor.id !== "")
                    &&
                    <>
                    <div className="form-group">
                        <div className="form-group__info">
                            <small className="form-group__small form-group__small--visible">Name of the author</small>
                        </div>
                        <input 
                            type="input" 
                            placeholder="Author Name" 
                            value={selectedAuthor.Author}
                            onChange={(e) => setSelectedAuthor({...selectedAuthor, Author: e.target.value})}
                        />
                    </div>

                    <div className="form-group form-group--authorImage">
                        <div className="form-group__info">
                            <small className="form-group__small form-group__small--visible">Image of the author</small>
                        </div>
                        <input
                            type="text"
                            placeholder="Author Image"
                            value={selectedAuthor.Img}
                            onChange={(e) => setSelectedAuthor({...selectedAuthor, Img: e.target.value})}
                        />
                    </div>

                    <div className="form-group">
                        <div className="form-group__info">
                            <small className="form-group__small form-group__small--visible">Description of the author</small>
                        </div>
                        <textarea 
                            name="Author description" 
                            placeholder="Description of the author"
                            value={selectedAuthor.Description}
                            onChange={(e) => setSelectedAuthor({...selectedAuthor, Description: e.target.value})}
                        ></textarea>
                    </div>

                    <div className="form-group form-group--authorImage">
                        <div className="form-group__info">
                            <small className="form-group__small form-group__small--visible">Id of the author</small>
                        </div>
                        <input
                            type="text"
                            placeholder="Author Id"
                            value={selectedAuthor.id}
                            onChange={(e) => setSelectedAuthor({...selectedAuthor, id: e.target.value})}
                        />
                    </div>
                    </>
                }
                
                {
                    mode === "add"
                    &&
                    <>
                    <h1>Add user</h1>
                    <div className="form-group">
                        <div className="form-group__info">
                            <small className="form-group__small form-group__small--visible">Name of the author</small>
                        </div>
                        <input 
                            type="input" 
                            placeholder="Author Name" 
                            value={newAuthor.Author}
                            onChange={(e) => setNewAuthor({...newAuthor, Author: e.target.value})} 
                        />
                    </div>

                    <div className="form-group form-group--authorImage">
                        <div className="form-group__info">
                            <small className="form-group__small form-group__small--visible">Image of the author</small>
                        </div>
                        <input
                            type="text"
                            placeholder="Author Image"
                            value={newAuthor.Img}
                            onChange={(e) => setNewAuthor({...newAuthor, Img: e.target.value})} 
                        />
                    </div>

                    <div className="form-group">
                        <div className="form-group__info">
                            <small className="form-group__small form-group__small--visible">Description of the author</small>
                        </div>
                        <textarea 
                            name="Author description" 
                            placeholder="Description of the author"
                            value={newAuthor.Description}
                            onChange={(e) => setNewAuthor({...newAuthor, Description: e.target.value})} 
                        ></textarea>
                    </div>
                    </>
                }

                {
                    error !== ''
                    &&
                    <div className="summary-error">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8 15.6H13.2V18H10.8V15.6ZM10.8 6H13.2V13.2H10.8V6ZM11.988 0C5.364 0 0 5.376 0 12C0 18.624 5.364 24 11.988 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 11.988 0ZM12 21.6C6.696 21.6 2.4 17.304 2.4 12C2.4 6.696 6.696 2.4 12 2.4C17.304 2.4 21.6 6.696 21.6 12C21.6 17.304 17.304 21.6 12 21.6Z" fill="#D53636"></path></svg>
                        <span>{error}</span>
                    </div>
                }

                {
                    (success && error === '')
                    &&
                    <div className="successful-message">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-bold</title><path d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" /></svg>
                        <span>{mode === "add" ? "Author submitted successfully!" : "Author details updated successfully!"}</span>
                    </div>
                }

                <div className="form-group form-group__assess-buttons">
                    <div className="form-group__left">
                        <button type="button" className="btn btn--assess" onClick={handleConfirmation}>
                            <span>Confirm</span>
                        </button>
                    </div>
                    <div className="form-group__right">
                        <button onClick={handleSubmit} type="button" disabled={!assessed} className="btn btn--black">
                            <span>Submit</span>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}