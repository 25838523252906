import * as React from 'react';
import SearchBar from '../functionalComponents/SearchBar';
import PopupMobileMenu from '../functionalComponents/PopupMobileMenu';

export default function Header({ author, setDisplayedArticles, triggerHomepageChangeApp, toggleNewsletterPopup }) {

    const [pathname, setPathname] = React.useState(window.location.pathname)
    const [mobileMenu, setMobileMenu] = React.useState(false);

    React.useEffect(() => {
        console.log(pathname)
    }, [])

    const handleChildStateChange = (childState) => {
        setDisplayedArticles(childState)
    };

    const toggleMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    };

    const triggerHomepageChange = () => {
        triggerHomepageChangeApp();
    };

    return (
        <div id="nea-header">
            <div className="header-main">
                <div className="container container--header">
                    <img src="https://i.imgur.com/dzUUqZM.png" alt="Logo" className="header-logo" />
                    <div className="header-main__links">
                        <a href="/" className={pathname === '/user' ? 'header-link header-link--active' : 'header-link'}>
                            <span>Home</span>
                        </a>
                        <a href="/aboutus" className={pathname === '/aboutus' ? 'header-link header-link--active' : 'header-link'}>
                            <span>About us</span>
                        </a>
                    </div>
                    <div className="header-main__search-bar-container">
                        {
                            pathname === "/"
                            &&
                            <SearchBar 
                                author={author} 
                                setDisplayedArticles={handleChildStateChange} 
                                triggerHomepageChange={triggerHomepageChange}
                            />
                        }
                        <button onClick={toggleMobileMenu} type="button" className="btn btn--header btn--mobile-menu">
                            <svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="6" y="8" width="24" height="2" rx="1" fill="#121212"/>
                                <rect x="6" y="14" width="24" height="2" rx="1" fill="#121212"/>
                            </svg>
                        </button>
                        <PopupMobileMenu 
                            isPopupVisible={mobileMenu} 
                            toggleMobileMenu={toggleMobileMenu} 
                        />
                    </div>
                    <div className="header-main__buttons">

                        <a href="/storysubmission" className="btn btn--grey header-button">
                            <svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.80298 15.9914C4.80298 15.9914 5.38523 8.49885 8.36582 8.49346C5.38523 8.46405 4.83671 1.00842 4.83671 1.00842" fill="black"/>
                                <path d="M4.80353 16.4999C4.79022 16.4999 4.7769 16.4994 4.76349 16.4983C4.48178 16.4767 4.2711 16.2322 4.2928 15.9524C4.29891 15.8739 4.44815 14.0089 4.97329 12.1098C5.46627 10.3271 6.1229 9.11668 6.93448 8.48949C6.12595 7.85946 5.47476 6.65036 4.98966 4.87307C4.47301 2.98003 4.33205 1.12365 4.32633 1.04553C4.30572 0.765601 4.51749 0.522034 4.7992 0.501451C5.07982 0.480378 5.32611 0.691405 5.34692 0.971235C5.48117 2.78449 6.3248 7.96502 8.37082 7.98521C8.65223 7.98795 8.8785 8.21603 8.87731 8.49567C8.87613 8.7753 8.64799 9.00152 8.36658 9.00201C6.32204 9.00573 5.45542 14.2085 5.31299 16.0309C5.29208 16.2974 5.06798 16.5 4.80344 16.5L4.80353 16.4999Z" fill="black"/>
                                <path d="M4.83671 1.00842C4.83671 1.00842 4.68421 8.6396 0.511597 8.71693C4.68421 8.6396 4.80307 15.9915 4.80307 15.9915" fill="black"/>
                                <path d="M4.8027 16.4999C4.52396 16.4999 4.29581 16.2776 4.29137 15.9997C4.29058 15.9572 4.19717 11.7115 2.36195 9.95163C1.83838 9.4496 1.23542 9.21143 0.520996 9.22515C0.238698 9.22907 0.00522773 9.00697 0 8.72636C0 8.72313 0 8.71979 0 8.71656C0 8.44025 0.222919 8.21374 0.502158 8.20865C4.13454 8.14141 4.32372 1.0696 4.3252 0.998152C4.33112 0.717636 4.56893 0.49426 4.84709 0.500239C5.12958 0.505924 5.35389 0.737926 5.34826 1.01854C5.34264 1.29847 5.19291 6.89742 2.36905 8.68353C2.61741 8.82947 2.85266 9.00893 3.0741 9.22143C5.21431 11.2757 5.31157 15.7922 5.31463 15.9835C5.31917 16.2642 5.09378 16.4955 4.81128 16.4999C4.80842 16.4999 4.80566 16.4999 4.8029 16.4999H4.8027Z" fill="black"/>
                            </svg>
                            <span>Submit an article</span>
                        </a>

                        <button onClick={toggleNewsletterPopup} type="button" className="btn btn--pink header-button">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.39955 1.00202C7.67136 0.548436 8.32864 0.548437 8.60045 1.00202L9.69243 2.82432C9.85094 3.08885 10.1639 3.21847 10.463 3.14351L12.5237 2.6271C13.0367 2.49856 13.5014 2.96333 13.3729 3.47627L12.8565 5.53697C12.7815 5.8361 12.9112 6.14906 13.1757 6.30757L14.998 7.39955C15.4516 7.67136 15.4516 8.32864 14.998 8.60045L13.1757 9.69243C12.9112 9.85094 12.7815 10.1639 12.8565 10.463L13.3729 12.5237C13.5014 13.0367 13.0367 13.5014 12.5237 13.3729L10.463 12.8565C10.1639 12.7815 9.85094 12.9112 9.69243 13.1757L8.60045 14.998C8.32864 15.4516 7.67136 15.4516 7.39955 14.998L6.30757 13.1757C6.14906 12.9112 5.8361 12.7815 5.53697 12.8565L3.47626 13.3729C2.96333 13.5014 2.49856 13.0367 2.6271 12.5237L3.14351 10.463C3.21848 10.1639 3.08885 9.85094 2.82432 9.69243L1.00202 8.60045C0.548436 8.32864 0.548437 7.67136 1.00202 7.39955L2.82432 6.30757C3.08885 6.14906 3.21847 5.8361 3.14351 5.53697L2.6271 3.47626C2.49856 2.96333 2.96333 2.49856 3.47626 2.6271L5.53697 3.14351C5.8361 3.21848 6.14906 3.08885 6.30757 2.82432L7.39955 1.00202Z" fill="white" />
                            </svg>
                            <span>Become a member</span>
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}