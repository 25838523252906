import React from 'react';
import axios from 'axios';

import formatDate from '../functionalComponents/formatDate';

export default function PopularArticles() {
    const [popularArticles, setPopularArticles] = React.useState([])

    let articles = <div className="pop-articles-info">Loading...</div>;

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://lumapi.neahub.co.uk/user/titlevip`);
                const popArticles = await response.json();
                setPopularArticles(popArticles)
    
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    if (popularArticles.length === 0){
        articles = <div className="pop-articles-info">There are currently no popular articles.</div>;
    } else {
        articles = popularArticles.map((article) => (
            <a href={`/user/article/${article.id}`} className="article article--compressed" key={article.id}>
                <span className="article--compressed__img-container">
                    <img src={article.Img} alt="Article img" className="article--compressed__img" />
                </span>

                <span className="article--compressed__author-info">
                    {
                        !article.Anon
                        ?
                        <>
                            <img src={(article.Author.Img === '' || article.Author.Img === ' ') ? "https://i.imgur.com/JKZEBC4.jpg" : `${article.Author.Img}`} alt="Author img" className="article--compressed__author-image" />
                            <span className="article--compressed__author-name">{article.Author.Author}</span>
                        </>
                        :
                        <>
                            <img src="https://i.imgur.com/JKZEBC4.jpg" alt="Author img" className="article--compressed__author-image" />
                            <span className="article--compressed__author-name">Anonymous</span>
                        </>

                    }
                    <i></i>
                    <span className="article--compressed__date">{formatDate(article.Date)}</span>
                </span>

                <span className="article--compressed__title">{article.Title}</span>
                <span className="article--compressed__description">{article.Abstract}</span>
                <span className="article--compressed__info">
                    <span className="article--compressed__vip">
                        <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.89543 0 2V24L7.78378 18.9818L15.5676 24V2C15.5676 0.895431 14.6721 0 13.5676 0H2ZM8.23215 3.50309C8.04875 3.13146 7.51882 3.13147 7.33542 3.50309L5.99379 6.22152C5.92096 6.36909 5.78017 6.47138 5.61732 6.49504L2.61735 6.93096C2.20724 6.99056 2.04348 7.49454 2.34024 7.78381L4.51104 9.89981C4.62888 10.0147 4.68266 10.1802 4.65484 10.3424L4.14238 13.3302C4.07233 13.7387 4.50105 14.0502 4.86786 13.8573L7.55111 12.4466C7.69677 12.3701 7.87079 12.3701 8.01645 12.4466L10.6997 13.8573C11.0665 14.0502 11.4952 13.7387 11.4252 13.3302L10.9127 10.3424C10.8849 10.1802 10.9387 10.0147 11.0565 9.89981L13.2273 7.78381C13.5241 7.49454 13.3603 6.99056 12.9502 6.93096L9.95025 6.49504C9.78739 6.47138 9.64661 6.36909 9.57378 6.22152L8.23215 3.50309Z" fill="#AE64AC"/>
                        </svg>
                    </span>
                    <span className="article--compressed__tags">
                        <span className="article--compressed__tag">{article.Tags[0]}</span>
                    </span>
                    <span className="article--compressed__views">
                        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.00008 1.33333C10.5267 1.33333 12.7801 2.75333 13.8801 5C12.7801 7.24667 10.5334 8.66667 8.00008 8.66667C5.46675 8.66667 3.22008 7.24667 2.12008 5C3.22008 2.75333 5.47341 1.33333 8.00008 1.33333ZM8.00008 0C4.66675 0 1.82008 2.07333 0.666748 5C1.82008 7.92667 4.66675 10 8.00008 10C11.3334 10 14.1801 7.92667 15.3334 5C14.1801 2.07333 11.3334 0 8.00008 0ZM8.00008 3.33333C8.92008 3.33333 9.66675 4.08 9.66675 5C9.66675 5.92 8.92008 6.66667 8.00008 6.66667C7.08008 6.66667 6.33342 5.92 6.33342 5C6.33342 4.08 7.08008 3.33333 8.00008 3.33333ZM8.00008 2C6.34675 2 5.00008 3.34667 5.00008 5C5.00008 6.65333 6.34675 8 8.00008 8C9.65341 8 11.0001 6.65333 11.0001 5C11.0001 3.34667 9.65341 2 8.00008 2Z" fill="black"/>
                        </svg>
                        <span>{article.Views === 1 ? '1 View' : `${article.Views} Views`}</span>
                    </span>
                </span>
            </a>
        ))
    }

    return (
        <div className="panel panel--homepageCompressedArticles">
            <div className="panel__heading">
                <div className="h3">Popular articles</div>
            </div>
            <div className="panel__body">

                {articles}

            </div>
        </div>
    )
}