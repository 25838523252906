import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function AboutUsPartners() {

    const [partners, setPartners] = useState([]);

    useEffect(() => {
        // Make the Axios POST request to fetch data from the endpoint
        axios
            .post('https://lumapi.neahub.co.uk/functions/frontpage/partners')
            .then((response) => {
                setPartners(response.data); // Assuming the response data is an array of team members
            })
    }, []);


    return (

        <>
            {
                partners.map((partner, index) => (

                    <div className="partner-el" key={index}>
                        <div className="partner-el__avatar-container avatar-container avatar-container--large">
                            <img src={partner.Img} alt={partner.Name} />
                        </div>
                        <b className="partner-el__name">{partner.Name}</b>
                        <span className="partner-el__position">{partner.Affiliation}</span>
                    </div>
                ))
            }
        </>

    );
}