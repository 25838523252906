import React from "react";

export default function Input(props) {
    const [value, setValue] = React.useState(props.value !== '' ? props.value : '');
    const [error, setError] = React.useState('');



    React.useEffect(() => {
        handleErrorCheck(props.value)
    }, [props.value])



    function handleInput(e) {
        setValue(e.target.value);

        handleErrorCheck(e.target.value);
        props.extractInfo(e.target.value, props.name);
    }



    function handleErrorCheck(value) {
        if (value.length > props.minCharacters && value.length < props.maxCharacters) {
            setError('');
        } else if (value.length < props.minCharacters) {
            setError(`${props.placeholder} must be at least ${props.minCharacters} characters`);
        } else if (value.length > props.maxCharacters){
            setError(`${props.placeholder} must be less than ${props.maxCharacters} characters`);
        }

        // FOR EMAIL TYPE
        if (props.type === "email") {
            if (!value.includes("@")) {
                setError(`Please enter an email address.`);
            } else {
                setError(``);
            }
        }
    }



    function handleFormGroupClass() {
        // If the elements is not filled
        if (value.length === 0 && error.length === 0) {
            return "form-group form-group--input";

        // If the element is filled, but there is an error.
        } else if (value.length > 0 && error.length > 0) {
            return "form-group form-group--input form-group--filled form-group--fail";

        // If the element is filled, and there is no error.
        } else if (value.length > 0 && error.length === 0) {
            return "form-group form-group--input form-group--filled form-group--success"

        // All other cases
        } else {
            return "form-group form-group--input";
        }
    }


    
    return (
        <div className={handleFormGroupClass()}>
            <div className="form-group__info">
                <small className="form-group__small">{props.placeholder}</small>
                <small className="form-group__small--characters">{(props.maxCharacters && props.minCharacters) && `${value.length}/${props.maxCharacters}`}</small>
            </div>
            <input 
                type={props.type} 
                placeholder={props.placeholder} 
                name={props.name} 
                value={value} 
                onChange={(e) => handleInput(e)} 
            />
            <div className="form-group__error">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.8 15.6H13.2V18H10.8V15.6ZM10.8 6H13.2V13.2H10.8V6ZM11.988 0C5.364 0 0 5.376 0 12C0 18.624 5.364 24 11.988 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 11.988 0ZM12 21.6C6.696 21.6 2.4 17.304 2.4 12C2.4 6.696 6.696 2.4 12 2.4C17.304 2.4 21.6 6.696 21.6 12C21.6 17.304 17.304 21.6 12 21.6Z" fill="#D53636"/>
                </svg>
                <div className="error-message">{error}</div>
            </div>
        </div>
    )
}

// PROPS:
// type
// placeholder
// name
// minCharacters
// maxCharacters
// extractInfo (func)