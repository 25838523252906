import * as React from 'react';
import axios from 'axios';

export default function SearchBar({ author, setDisplayedArticles, triggerHomepageChange }) {
    const [searchInput, setSearchInput] = React.useState('');
    const [timeoutId, setTimeoutId] = React.useState(null);



    React.useEffect(() => {
        // We check if the author is an empty string
        let authorName = author;
        if (authorName.length > 0) {
            handleAuthor(authorName);
        }
    }, [author])



    // Searches for articles if the user stops writing in the input field for 1 second
    React.useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const newTimeoutId = setTimeout(() => {
            handleSearch();
        }, 1000);

        setTimeoutId(newTimeoutId);

        return () => clearTimeout(newTimeoutId);
    }, [searchInput])
    // 



    const handleAuthor = (author) => {
        setSearchInput(author);
        const formattedString = author.replace(/ *, */g, ',');
        const searchWords = formattedString.split(',');
        const lowerCaseWords = searchWords.map(words => words.toLowerCase())
        sendSearchRequest(lowerCaseWords);
    };

    

    const handleInputChange = (event) => {
        setSearchInput(event.target.value);
        triggerHomepageChange();
    };



    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setSearchInput(event.target.value);
            handleSearch();
        }
    };



    const handleSearch = () => {
        const formattedString = searchInput.replace(/ *, */g, ',');
        const searchWords = formattedString.split(',');
        const lowerCaseWords = searchWords.map(words => words.toLowerCase())
        sendSearchRequest(lowerCaseWords);
    };



    const sendSearchRequest = (searchWords) => {
        const searchData = { words: searchWords };

        // Make the POST request to your backend API
        axios.post('https://lumapi.neahub.co.uk/user/title', searchData)
            .then(displayedArticles => {
                console.log(displayedArticles, "displayedArticles")
                setDisplayedArticles(displayedArticles);
            })
            .catch((error) => {
                console.error('Error sending search request:', error);
            });
    };



    return (
        <div className="searchBar">
            <input
                type="text"
                value={searchInput}
                onChange={(e) => handleInputChange(e)}
                onKeyDown={(e) => handleKeyPress(e)}
                placeholder="Search"
            />
            <button type="button" className="btn btn--transparent" onClick={handleSearch}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z" fill="black"/>
                </svg>
            </button>
        </div>
    )
}