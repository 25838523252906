import * as React from 'react';
import Home from "../pageComponents/Home";
import Login from "../pageComponents/Login";
import Register from "../pageComponents/Register";
import ArticlePage from "../pageComponents/ArticlePage";
import AboutUsPage from "../pageComponents/AboutUsPage";
import StorySubmissionPage from "../pageComponents/StorySubmissionPage";
import AssessUserPage from '../pageComponents/AssessUserPage';
import AssessArticlePage from '../pageComponents/AssessArticlePage';
import SuccessPage from '../pageComponents/SuccessPage';
// import ReactDOM from 'react-dom/client';

import { Routes, Route, Navigate } from 'react-router-dom';

const apiKey = process.env.SUPABASE_KEY

export default function Main(props) {
    const [articlesList, setArticlesList] = React.useState([]);



    React.useEffect(() => {
        setArticlesList(props.articles.data);
    }, [props.articles])



    function handleAuthorSorting (author) {
        props.handleAuthorSorting(author);
    }



    function toggleNewsletterPopup () {
        props.toggleNewsletterPopup();
    }



    return (
        <Routes>
            <Route path="/user" element={<Navigate to="/" />} />
            <Route path='/' element={
                <Home 
                    articles={articlesList} 
                    handleAuthorSorting={handleAuthorSorting} 
                    toggleHomepage={props.toggleHomepage} 
                    toggleNewsletterPopup={toggleNewsletterPopup}
                />
            }>
            </Route>
            <Route path='/login' element={<Login />}></Route>
            <Route path='/register' element={<Register />}></Route>
            <Route path='/aboutus' element={<AboutUsPage toggleNewsletterPopup={toggleNewsletterPopup} />}></Route>
            <Route path="/storysubmission" element={<StorySubmissionPage />} ></Route>
            <Route path="/admin/assess/user" element={<AssessUserPage />} ></Route>
            <Route path="/admin/assess/article" element={<AssessArticlePage />} ></Route>
            <Route path='/user/test123' element={<div>This is another test page</div>}></Route>
            <Route path='/congratulations' element={<SuccessPage />}></Route>
            <Route path='/notokens' element={<div className="page-content page-content--login">Wrong Account Information</div>}></Route>
            <Route path='/noaccount' element={<div className="page-content page-content--login">Wrong Account Information</div>}></Route>
            <Route path="/user/article/:userId" element={<ArticlePage toggleNewsletterPopup={toggleNewsletterPopup} />} > </Route>
        </Routes>
    );
}