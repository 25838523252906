import React, { useState, useEffect } from 'react';

import HTMLRenderer from "../functionalComponents/HTMLRenderer"
import { useParams } from 'react-router-dom';

import Author from '../functionalComponents/Author';
import ShareAndViews from '../functionalComponents/ShareAndViews';
import PopularArticles from '../functionalComponents/PopularArticles';
import PopupNewsletter from '../functionalComponents/PopupNewsletter';

import formatDate from '../functionalComponents/formatDate';
// import { ListFormat, TypeFlags } from 'typescript';
// import { ListSeparator } from 'sass';
// import { MyData } from '../functionalComponents/dataStructure.js'

export default function ArticlePage(props) {
    const [article, setArticlesList] = useState({
        Abstract: "",
        Anon: true,
        Assessed: false,
        Author: {
            Author: '',
            Description: '',
            Img: '',
            id: ''
        },
        Contact: '',
        Date: '',
        Frontpage: false,
        Img: '',
        Likes: 0,
        Person: '',
        Story: '',
        Tags: [],
        Title: '',
        Views: 0,
        authorId: '',
        id: ''
    });
    const [loading, setLoading] = useState(true);



    useEffect(() => {
        fetchData();
    }, []);

    const { userId } = useParams()

    const formattedDate = formatDate(article.Date);

    const fetchData = async () => {
        try {
            const response = await fetch(`https://lumapi.neahub.co.uk/user/article/${userId}`);
            const responseJSON = await response.json();
            setArticlesList(responseJSON);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    };



    function toggleNewsletterPopup () {
        props.toggleNewsletterPopup();
    }

    if (article.Author == null) {
        setArticlesList({...article, Author: { "Author": "Anonymous", "Img": "https://i.imgur.com/JKZEBC4.jpg", "Description": "" }});
    }

    return (

        <div className="page-content page-content--article-page">

            <div className="page-content__inner">
                <div className="container">
                    <div className="page-main">

                        <a href="/" className="btn btn--grey btn--back">
                            <svg width="24" height="40" viewBox="0 0 24 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.34 3.54L19.78 0L0 19.8L19.8 39.6L23.34 36.06L7.08 19.8L23.34 3.54Z" fill="black"/>
                            </svg>
                        </a>

                        <div className="panel panel--article">

                            <div className="article__top">
                                <div className="article__top__left">
                                    <div className="article__tags">
                                        <div className="article__tag">{article.Tags[0]}</div>
                                    </div>
                                    {
                                        !article.Anon ?
                                            <Author src={article.Author.Img} name={article.Author.Author} /> :
                                            <div className="author">
                                                <div className="author__img__container">
                                                    <img src="https://i.imgur.com/JKZEBC4.jpg" className="author__img" alt="Author" />
                                                </div>
                                                <span className="author__name">Anonymous</span>
                                            </div>
                                    }
                                    <i></i>
                                    <span className="article__author__date">{formattedDate}</span>
                                </div>
                                <ShareAndViews views={article.Views} />
                            </div>

                            <div className="article__body">
                                <HTMLRenderer className="article__title" htmlString={article.Title} />

                                <HTMLRenderer className="article__abstract" htmlString={article.Abstract} />
                                <HTMLRenderer className="article__story" htmlString={article.Story} />

                                {/* USE FOR TESTING */}

                                {/* <div className="article__abstract">
                                    In today's diverse world, it is crucial to recognize and celebrate the unique contributions of individuals who identify as neurodivergent. Neurodivergent people of color face intersecting challenges and experiences that are often overlooked or misunderstood. It is essential to foster a more inclusive society that not only acknowledges their struggles but also embraces their strengths and empowers them to thrive. This article explores the experiences, challenges, and triumphs of neurodivergent people of color and highlights the importance of supporting and celebrating their diverse voices.
                                </div>
                                <div className="article__story">
                                    <h1>This is an h1 element Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when</h1>
                                    <h2>This is an h2 element Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when</h2>
                                    <h3>This is an h3 element Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when</h3>
                                    <h4>This is an h4 element Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when</h4>
                                    <h5>This is an h5 element Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when</h5>
                                    <h6>This is an h6 element Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when</h6>

                                    <p>This is a p element</p>

                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also</p>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also</p>

                                    <b>This is a b element</b>

                                    <span>This is a span element</span>

                                    <small>This is a small element</small>

                                    <strong>This is a strong element</strong>

                                    <a href="/">This is an anchor element</a>

                                    <ul>
                                        <li>Li element of a ul</li>
                                        <li>Li element of a ul</li>
                                        <li>Li element of a ul</li>
                                        <li>Li element of a ul</li>
                                    </ul>

                                    <ol>
                                        <li>Li element of a ol</li>
                                        <li>Li element of a ol</li>
                                        <li>Li element of a ol</li>
                                        <li>Li element of a ol</li>
                                    </ol>

                                    <img src="https://i.imgur.com/8A2IJni.jpg" alt="Some image" />
                                </div> */}

                                {/*  */}

                            </div>

                            <div className="article__tags-and-share">
                                <div className="article__tags-and-share__left">
                                    <div className="article__tags">
                                        {
                                            article.Tags.map((tag, index) => (
                                                <div className="article__tag article__tag--large" key={index}>{tag}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="article__tags-and-share__right">
                                    <ShareAndViews views={article.Views} />
                                </div>
                            </div>

                            <div className="article__author">
                                <div className="article__author__left">
                                    <div className="h6">Author</div>
                                    {
                                        !article.Anon ?
                                        <Author src={article.Author.Img} name={article.Author.Author} /> :
                                        <div className="author">
                                            <div className="author__img__container">
                                                <img src="https://i.imgur.com/JKZEBC4.jpg" className="author__img" alt="Author" />
                                            </div>
                                            <span className="author__name">Anonymous</span>
                                        </div>
                                    }
                                </div>
                                <div className="article__author__right">
                                    <div className="h6">About the author</div>
                                    <p>{article.Author.Description}</p>
                                </div>
                            </div>

                            <div className="article__tags--mobile">
                                <div className="h6">Categories</div>
                                <div className="article__tags__body">
                                    {
                                        article.Tags.map((tag, index) => (
                                            <div className="article__tag article__tag--large" key={index}>{tag}</div>
                                        ))
                                    }
                                </div>
                            </div>

                        </div>
                        
                        <PopularArticles />

                    </div>
                </div>
            </div>

            <div className="page-content__inner page-content__inner--newsletter">
                <div className="container container--newsletter">
                    <div className="panel panel--newsletter">
                        <div className="panel__body">
                            <h4>Get notified about our stories as they are published. Never miss by subscribing to our newsletter!</h4>
                            <button 
                                type="button" 
                                className="btn btn--pink"
                                onClick={toggleNewsletterPopup}
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3996 1.00202C11.6714 0.548437 12.3286 0.548437 12.6004 1.00202L14.6721 4.45919C14.8306 4.72371 15.1436 4.85334 15.4427 4.77838L19.3522 3.79868C19.8651 3.67014 20.3299 4.13491 20.2013 4.64784L19.2216 8.5573C19.1467 8.85643 19.2763 9.16939 19.5408 9.3279L22.998 11.3996C23.4516 11.6714 23.4516 12.3286 22.998 12.6004L19.5408 14.6721C19.2763 14.8306 19.1467 15.1436 19.2216 15.4427L20.2013 19.3522C20.3299 19.8651 19.8651 20.3299 19.3522 20.2013L15.4427 19.2216C15.1436 19.1467 14.8306 19.2763 14.6721 19.5408L12.6004 22.998C12.3286 23.4516 11.6714 23.4516 11.3996 22.998L9.3279 19.5408C9.16939 19.2763 8.85643 19.1467 8.5573 19.2216L4.64784 20.2013C4.13491 20.3299 3.67014 19.8651 3.79868 19.3522L4.77838 15.4427C4.85334 15.1436 4.72371 14.8306 4.45919 14.6721L1.00202 12.6004C0.548437 12.3286 0.548437 11.6714 1.00202 11.3996L4.45919 9.3279C4.72371 9.16939 4.85334 8.85643 4.77838 8.5573L3.79868 4.64784C3.67014 4.13491 4.13491 3.67014 4.64784 3.79868L8.5573 4.77838C8.85643 4.85334 9.16939 4.72371 9.3279 4.45919L11.3996 1.00202Z" fill="white"/>
                                </svg>
                                <span>Subscribe our newsletter</span>
                            </button>

                            {/* <PopupNewsletter isPopupVisible={isPopupVisible} toggleNewsletterPopup={toggleNewsletterPopup} /> */}

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}