export default function ArticleSkeleton () {
    return (
        <div className="article-skeleton-container">
            
            <div className="article-skeleton">
                <div className="article-skeleton__top">
                    <div className="article-skeleton__top__avatar skeleton-bg"></div>
                    <div className="article-skeleton__top__name skeleton-bg"></div>
                    <div className="article-skeleton__top__date skeleton-bg"></div>
                </div>
                <div className="article-skeleton__main">
                    <div className="article-skeleton__main__left">
                        <div className="article-skeleton__main__title skeleton-bg"></div>
                        <div className="article-skeleton__main__desc skeleton-bg"></div>
                    </div>
                    <div className="article-skeleton__main__right">
                        <div className="article-skeleton__main__image skeleton-bg"></div>
                    </div>
                </div>
                <div className="article-skeleton__bottom">
                    <div className="article-skeleton__bottom__tag skeleton-bg"></div>
                    <div className="article-skeleton__bottom__shareBtn skeleton-bg"></div>
                    <div className="article-skeleton__bottom__views skeleton-bg"></div>
                </div>
                <div className="article-skeleton__line skeleton-bg"></div>
            </div>

            <div className="article-skeleton">
                <div className="article-skeleton__top">
                    <div className="article-skeleton__top__avatar skeleton-bg"></div>
                    <div className="article-skeleton__top__name skeleton-bg"></div>
                    <div className="article-skeleton__top__date skeleton-bg"></div>
                </div>
                <div className="article-skeleton__main">
                    <div className="article-skeleton__main__left">
                        <div className="article-skeleton__main__title skeleton-bg"></div>
                        <div className="article-skeleton__main__desc skeleton-bg"></div>
                    </div>
                    <div className="article-skeleton__main__right">
                        <div className="article-skeleton__main__image skeleton-bg"></div>
                    </div>
                </div>
                <div className="article-skeleton__bottom">
                    <div className="article-skeleton__bottom__tag skeleton-bg"></div>
                    <div className="article-skeleton__bottom__shareBtn skeleton-bg"></div>
                    <div className="article-skeleton__bottom__views skeleton-bg"></div>
                </div>
                <div className="article-skeleton__line skeleton-bg"></div>
            </div>

            <div className="article-skeleton">
                <div className="article-skeleton__top">
                    <div className="article-skeleton__top__avatar skeleton-bg"></div>
                    <div className="article-skeleton__top__name skeleton-bg"></div>
                    <div className="article-skeleton__top__date skeleton-bg"></div>
                </div>
                <div className="article-skeleton__main">
                    <div className="article-skeleton__main__left">
                        <div className="article-skeleton__main__title skeleton-bg"></div>
                        <div className="article-skeleton__main__desc skeleton-bg"></div>
                    </div>
                    <div className="article-skeleton__main__right">
                        <div className="article-skeleton__main__image skeleton-bg"></div>
                    </div>
                </div>
                <div className="article-skeleton__bottom">
                    <div className="article-skeleton__bottom__tag skeleton-bg"></div>
                    <div className="article-skeleton__bottom__shareBtn skeleton-bg"></div>
                    <div className="article-skeleton__bottom__views skeleton-bg"></div>
                </div>
                <div className="article-skeleton__line skeleton-bg"></div>
            </div>

        </div>
    )
}