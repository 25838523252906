import React, { useState, useEffect } from 'react';
import { ListSeparator } from 'sass';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { MyData } from '../functionalComponents/dataStructure.jsx'



export default function Login() {
    const token = localStorage.getItem('token');

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPass] = useState('');

    const handleSubmitLogin = (e) => {
        e.preventDefault();
        try {
            axios.post('https://lumapi.neahub.co.uk/auth/login', { email, password })
                .then((response) => {
                    const { token } = response.data;
                    // Save the token to local storage
                    localStorage.setItem('token', token);
                    navigate('/congratulations');
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        console.log('Unauthorized request');
                        navigate('/noaccount');
                    } else {
                        console.log('Other error occurred');
                        console.log(error);
                        navigate('/');
                    }
                });
        } catch (error) {
            console.log('Error caught during request');
            console.log(error);
            navigate('/');
        }
    }

    const TestAuth = (e) => {
        const token = localStorage.getItem('token');
        e.preventDefault();
        try {
            axios.post('https://lumapi.neahub.co.uk/admin', {
                headers: {
                    "Authorization": token,
                },
            })
                .then((response) => {
                    console.log(response);

                    // Handle the response
                })
                .catch((error) => {
                    navigate("/notokens");

                    // Handle errors
                });

        } catch (error) {
            console.log(error)
            navigate("/");
        }
    }
    const handleLogout = () => {
        // Remove the token from local storage
        localStorage.removeItem('token');

        // Perform any additional logout logic or redirect the user
        // For example, you can reset the user state, clear any cached data, or redirect to the login page.
    };



    return (

        <div className="page-content page-content--login">
            <h1>You're logged in! Welcome to Luminous.</h1>
            <div className="page-content--login__body">
                <a href="/admin/assess/user" >Edit Authors</a>
                <a href="/admin/assess/article" >Edit Articles</a>
            </div>
        </div>
    )
}