import React from 'react';

export default function ShareAndViews (props) {
    const [toggledMenu, handleToggledMenu] = React.useState(false);

    function handleShare(platform) {

        // const urlToShare = props.sharedLink;
        const urlToShare = window.location.href;
        let finalUrl;

        if (platform === "facebook"){
            finalUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}`;
        } else if (platform === "x"){
            finalUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(urlToShare)}`;
        } else if (platform === "linkedin"){
            finalUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(urlToShare)}`;
        }

        window.open(finalUrl, '_blank', 'width=600,height=400');
    }

    function handleInstagramShare () {
        // const urlToShare = props.sharedLink;
        const urlToShare = "https://www.lipsum.com/";

        // Instagram
        const instagramShareUrl = `instagram://share?url=${encodeURIComponent(urlToShare)}`;
        const fallbackUrl = 'https://www.instagram.com/';

        // Try to open the Instagram app, if available
        window.location.href = instagramShareUrl;

        // If the Instagram app is not installed or failed to open, fall back to the web URL
        setTimeout(() => {
            window.location.href = fallbackUrl;
        }, 500);
    }

    return (
        <div className="article__info__right">

            <div className={toggledMenu ? "menu menu--container menu--share menu--open" : "menu menu--container menu--share"}>
                <button type="button" className="btn btn--grey menu__toggle" onClick={() => handleToggledMenu(!toggledMenu)}>
                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 9.72004C9.49333 9.72004 9.04 9.92004 8.69333 10.2334L3.94 7.46671C3.97333 7.31337 4 7.16004 4 7.00004C4 6.84004 3.97333 6.68671 3.94 6.53337L8.64 3.79337C9 4.12671 9.47333 4.33337 10 4.33337C11.1067 4.33337 12 3.44004 12 2.33337C12 1.22671 11.1067 0.333374 10 0.333374C8.89333 0.333374 8 1.22671 8 2.33337C8 2.49337 8.02667 2.64671 8.06 2.80004L3.36 5.54004C3 5.20671 2.52667 5.00004 2 5.00004C0.893333 5.00004 0 5.89337 0 7.00004C0 8.10671 0.893333 9.00004 2 9.00004C2.52667 9.00004 3 8.79337 3.36 8.46004L8.10667 11.2334C8.07333 11.3734 8.05333 11.52 8.05333 11.6667C8.05333 12.74 8.92667 13.6134 10 13.6134C11.0733 13.6134 11.9467 12.74 11.9467 11.6667C11.9467 10.5934 11.0733 9.72004 10 9.72004ZM10 1.66671C10.3667 1.66671 10.6667 1.96671 10.6667 2.33337C10.6667 2.70004 10.3667 3.00004 10 3.00004C9.63333 3.00004 9.33333 2.70004 9.33333 2.33337C9.33333 1.96671 9.63333 1.66671 10 1.66671ZM2 7.66671C1.63333 7.66671 1.33333 7.36671 1.33333 7.00004C1.33333 6.63337 1.63333 6.33337 2 6.33337C2.36667 6.33337 2.66667 6.63337 2.66667 7.00004C2.66667 7.36671 2.36667 7.66671 2 7.66671ZM10 12.3467C9.63333 12.3467 9.33333 12.0467 9.33333 11.68C9.33333 11.3134 9.63333 11.0134 10 11.0134C10.3667 11.0134 10.6667 11.3134 10.6667 11.68C10.6667 12.0467 10.3667 12.3467 10 12.3467Z" fill="black" />
                    </svg>
                    <span>Share</span>
                </button>
                <div className="menu__dd">
                    <div className="share-buttons-container">
                        <button 
                            type="button" 
                            className="btn btn--transparent btn--share btn--share--facebook"
                            onClick={() => handleShare('facebook')}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>facebook</title><path d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" /></svg>
                        </button>
                        <button 
                            type="button" 
                            className="btn btn--transparent btn--share btn--share--instagram"
                            onClick={handleInstagramShare}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>instagram</title><path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" /></svg>
                        </button>
                        <button 
                            type="button" 
                            className="btn btn--transparent btn--share btn--share--x"
                            onClick={() => handleShare('x')}
                        >
                            <svg width="100" height="100" viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg"> <path d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66"/> </svg>
                        </button>
                        <button 
                            type="button" 
                            className="btn btn--transparent btn--share btn--share--linkedin"
                            onClick={() => handleShare('linkedin')}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>linkedin</title><path d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z" /></svg>
                        </button>
                    </div>
                </div>
            </div>

            <span className="article__views">
                <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.00002 1.33333C10.5267 1.33333 12.78 2.75333 13.88 5C12.78 7.24667 10.5334 8.66667 8.00002 8.66667C5.46669 8.66667 3.22002 7.24667 2.12002 5C3.22002 2.75333 5.47335 1.33333 8.00002 1.33333ZM8.00002 0C4.66669 0 1.82002 2.07333 0.666687 5C1.82002 7.92667 4.66669 10 8.00002 10C11.3334 10 14.18 7.92667 15.3334 5C14.18 2.07333 11.3334 0 8.00002 0ZM8.00002 3.33333C8.92002 3.33333 9.66669 4.08 9.66669 5C9.66669 5.92 8.92002 6.66667 8.00002 6.66667C7.08002 6.66667 6.33335 5.92 6.33335 5C6.33335 4.08 7.08002 3.33333 8.00002 3.33333ZM8.00002 2C6.34669 2 5.00002 3.34667 5.00002 5C5.00002 6.65333 6.34669 8 8.00002 8C9.65335 8 11 6.65333 11 5C11 3.34667 9.65335 2 8.00002 2Z" fill="black" />
                </svg>
                <b className="number-of-views">{props.views !== 1 ? `${props.views} Views` : `${props.views} View`}</b>
            </span>
            <span className="article__views--mobile">
                <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.99984 1.33333C10.5265 1.33333 12.7798 2.75333 13.8798 5C12.7798 7.24667 10.5332 8.66667 7.99984 8.66667C5.4665 8.66667 3.21984 7.24667 2.11984 5C3.21984 2.75333 5.47317 1.33333 7.99984 1.33333ZM7.99984 0C4.6665 0 1.81984 2.07333 0.666504 5C1.81984 7.92667 4.6665 10 7.99984 10C11.3332 10 14.1798 7.92667 15.3332 5C14.1798 2.07333 11.3332 0 7.99984 0ZM7.99984 3.33333C8.91984 3.33333 9.6665 4.08 9.6665 5C9.6665 5.92 8.91984 6.66667 7.99984 6.66667C7.07984 6.66667 6.33317 5.92 6.33317 5C6.33317 4.08 7.07984 3.33333 7.99984 3.33333ZM7.99984 2C6.3465 2 4.99984 3.34667 4.99984 5C4.99984 6.65333 6.3465 8 7.99984 8C9.65317 8 10.9998 6.65333 10.9998 5C10.9998 3.34667 9.65317 2 7.99984 2Z" fill="#5A5A5A"/>
                </svg>
                <b className="number-of-views">{props.views}</b>
                <i></i>
                <b className="number-of-views">Published {props.formattedDate}</b>
            </span>
        </div>
    )
}