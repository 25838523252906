import React from 'react';

import Input from "../functionalComponents/Input";
import Textarea from '../functionalComponents/Textarea';
import Select from '../functionalComponents/Select';
import axios from 'axios';

import HTMLRenderer from '../functionalComponents/HTMLRenderer';

let submissionInfo = {
    "Email": "",
    "Title": "",
    "Categories": [],
    "Short desription": "",
    "Story": "",
    "About": ""
}



export default function StorySubmissionPage () {
    const [formInfo, setFormInfo] = React.useState([]);
    const [displayedSection, setDisplayedSection] = React.useState(1);
    const [errorFound, setErrorFound] = React.useState("");
    const [categories, setCategories] = React.useState([""]);



    React.useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const url = "https://lumapi.neahub.co.uk/functions/tags";
        
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setCategories(data.Tags);
            })
    }, [])



    function handleDisplayedSectionClass (sectionNumber) {
        if (displayedSection === sectionNumber) {
            return "currenct-section currenct-section--current";
        } else if (displayedSection > sectionNumber) {
            return "currenct-section currenct-section--prev";
        } else {
            return "currenct-section";
        }
    }



    function handleDisplayedSection(sectionChange) {
        if (sectionChange === "forwards") {
            setDisplayedSection(displayedSection + 1)
        } else {
            setDisplayedSection(displayedSection - 1)
        }

        if (displayedSection === 2){
            handleMarkdown();
        }

        handleError();
    }



    function handleMarkdown () {
        console.log('fuskane')
        axios.post("https://lumapi.neahub.co.uk/functions/markdown/render", {
            "markdown": submissionInfo.Story
        })
        .then((response) => {
            console.log(response.data);
            // submissionInfo.Story = response.data;
            submissionInfo.HTML = <HTMLRenderer className="storyComponent" htmlString={response.data} />
        });
    }



    // Massive check for errors
    function handleError () {
        console.log("[handleError]");
        if (submissionInfo.Title.length < 15){
            setErrorFound("The title of your article is too short.");
            return;
        } else if (submissionInfo.Title.length > 50){
            setErrorFound("The title of your article is too long.");
            return;
        } else if (submissionInfo.Categories.length === 0) {
            setErrorFound("Please select at least 1 category.");
            return;
        } else if (submissionInfo.Categories.length > 5) {
            setErrorFound("Please select no more than 5 categories.");
            return;
        } else if (submissionInfo["Short desription"].length < 50) {
            setErrorFound("The description of your story is too short.");
            return;
        } else if (submissionInfo["Short desription"].length > 300) {
            setErrorFound("The description of your story is too long.");
            return;
        } else if (submissionInfo["Story"].length < 50) {
            setErrorFound("The story you have written is too short.");
            return;
        } else if (submissionInfo["Story"].length > 4000) {
            setErrorFound("The story you have written is too long.");
            return;
        } else if (!submissionInfo.Email.includes('@')) {
            setErrorFound("You have not entered a valid email.");
            return;
        } else if (submissionInfo.About.length < 30) {
            setErrorFound("Please share more about yourself.");
            return;
        }

        setErrorFound("");
    }
    // 



    // Handles the submission of the form
    function submitArticle() {
        setFormInfo(submissionInfo);
        handleDisplayedSection('forwards');

        const url = "https://lumapi.neahub.co.uk/user/article";
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(submissionInfo)
        })
        .then(response => response.json())
        .catch(error => {
            console.log(error)
        });
    }
    // 



    // Extracts the information from the elements of the form
    function extractInfo(value, info) {
        if (info === "title") {
            submissionInfo.Title = value;

        } else if (info === "Email"){
            submissionInfo.Email = value;

        } else if (info === "Short description"){
            submissionInfo["Short desription"] = value;

        } else if (info === "Story"){
            submissionInfo["Story"] = value;

        } else if (info === "share"){
            submissionInfo["About"] = value;

        } else if (info === "Categories"){
            submissionInfo["Categories"] = value;
        }
    }
    // 
    let storyTemplate = "# Use this tag for a heading in your article\n\nWrite a short introduction here\n\n## Use this for a smaller heading\n\nIf you have any bullet points to say such as achievements use:\n\n+ Bullet Point 1\n+ Bullet Point 2\nIf you want to add an image to your article use:\n\n![Alternate Text if image doesn't load.](https://i.imgur.com/8L65b0y.jpeg)\n\n# Conclusion\n\nThis should conclude how to write a story and make it look good.\n\n# Add a video\n\n<iframe src='YoutubeURLHere'  class='youtube-embed' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowfullscreen></iframe>"


    return (
        <div className="page-content page-content--submission">
            <div className="page-content__inner">

                <div className="container">

                    <div className={displayedSection > 4 ? "panel panel--submission panel--submission--submitted" : "panel panel--submission"}>

                        {
                            displayedSection > 4
                            &&
                            <>
                                <div className="doodle-container doodle-container--1 doodle-container--story-submission">
                                    <img src="https://i.imgur.com/sml1SuF.png" alt="Doodle" />
                                </div>
                                <div className="doodle-container doodle-container--2 doodle-container--story-submission">
                                    <img src="https://i.imgur.com/GMWeahw.png" alt="Doodle" />
                                </div>
                                <div className="doodle-container doodle-container--3 doodle-container--story-submission">
                                    <img src="https://i.imgur.com/4HaUUFX.png" alt="Doodle" />
                                </div>
                            </>
                        }

                        <div className="panel__heading">
                            <a href="/" className="btn btn--grey btn--back">
                                <svg width="24" height="40" viewBox="0 0 24 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.34 3.54L19.78 0L0 19.8L19.8 39.6L23.34 36.06L7.08 19.8L23.34 3.54Z" fill="black"/>
                                </svg>
                            </a>
                            <h1>
                                {
                                    displayedSection > 4
                                    ?
                                    "Successfully submitted your story!"
                                    :
                                    "Story submission form"
                                }
                            </h1>
                            <h6>
                                {
                                    displayedSection > 4
                                    &&
                                    <span>
                                        We will contact you <b>({submissionInfo.Email})</b> if your story is selected. Regardless of the result, we appreciate your contribution!
                                    </span>
                                }
                            </h6>
                        </div>
                        <div className="panel__body">

                            {
                                displayedSection < 5
                                &&
                                <div className="currenct-sections-container">
                                    <div className={handleDisplayedSectionClass(1)}>
                                        <span>Intro</span>
                                    </div>
                                    <div className="currenct-section-separator"></div>
                                    <div className={handleDisplayedSectionClass(2)}>
                                        <span>Article</span>
                                    </div>
                                    <div className="currenct-section-separator"></div>
                                    <div className={handleDisplayedSectionClass(3)}>
                                        <span>Author</span>
                                    </div>
                                    <div className="currenct-section-separator"></div>
                                    <div className={handleDisplayedSectionClass(4)}>
                                        <span>Review</span>
                                    </div>
                                </div>
                            }

                            {
                                displayedSection === 1
                                &&
                                <div className="submission-section submission-section--1">
                                    <Input 
                                        type="text" 
                                        value={submissionInfo.Title}
                                        placeholder="Title"
                                        name="title"
                                        minCharacters={15}
                                        maxCharacters={50}
                                        extractInfo={extractInfo}
                                    />
                                    <Select
                                        selectOptions={submissionInfo.Categories}
                                        placeholder="Category"
                                        optionsList={categories}
                                        extractInfo={extractInfo}
                                    />
                                    <Textarea
                                        value={submissionInfo["Short desription"]}
                                        placeholder="Short description"
                                        name="Short description"
                                        minCharacters={50}
                                        maxCharacters={300}
                                        extractInfo={extractInfo}
                                    />
                                </div>
                            }

                            {
                                displayedSection === 2
                                &&
                                <div className="submission-section submission-section--2">
                                    <Textarea
                                        value={submissionInfo.Story || storyTemplate}
                                        placeholder="Story"
                                        name="Story"
                                        minCharacters={50}
                                        maxCharacters={4000}
                                        extractInfo={extractInfo}
                                    />
                                </div>
                            }

                            {
                                displayedSection === 3
                                &&
                                <div className="submission-section submission-section--3">
                                    <Input 
                                        value={submissionInfo.Email}
                                        type="email" 
                                        placeholder="Email"
                                        name="Email"
                                        extractInfo={extractInfo}
                                    />
                                    <Textarea
                                        value={submissionInfo.About}
                                        placeholder="Tell us about yourself"
                                        name="share"
                                        minCharacters={30}
                                        maxCharacters={300}
                                        extractInfo={extractInfo}
                                    />
                                </div>
                            }

                            {
                                displayedSection === 4
                                &&
                                <div className="submission-section submission-section--4">
                                    <div className="submission-section__subsection">
                                        <div className="submission-section__subheading">Email</div>
                                        <div className="submission-section__subbody">{submissionInfo.Email}</div>
                                    </div>
                                    <div className="submission-section__subsection">
                                        <div className="submission-section__subheading">Author</div>
                                        <div className="submission-section__subbody">{submissionInfo.About}</div>
                                    </div>
                                    <div className="submission-section__subsection">
                                        <div className="submission-section__subheading">Title</div>
                                        <div className="submission-section__subbody">{submissionInfo.Title}</div>
                                    </div>
                                    <div className="submission-section__subsection">
                                        <div className="submission-section__subheading">Categories</div>
                                        <div className="submission-section__subbody tags-container">
                                            {
                                                submissionInfo.Categories.map((category, index) => (
                                                    <div className="tag" key={index}>{category}</div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="submission-section__subsection">
                                        <div className="submission-section__subheading">Short description</div>
                                        <div className="submission-section__subbody">{submissionInfo["Short desription"]}</div>
                                    </div>
                                    <div className="submission-section__subsection">
                                        <div className="submission-section__subheading">Story</div>
                                            <div className="submission-section__subbody">{submissionInfo.HTML}</div>
                                    </div>
                                </div>
                            }

                            {
                                displayedSection === 4 && errorFound !== ''
                                &&
                                <div className="submission-section submission-section--4 submission-section--summary-error">
                                    <div className="summary-error">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.8 15.6H13.2V18H10.8V15.6ZM10.8 6H13.2V13.2H10.8V6ZM11.988 0C5.364 0 0 5.376 0 12C0 18.624 5.364 24 11.988 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 11.988 0ZM12 21.6C6.696 21.6 2.4 17.304 2.4 12C2.4 6.696 6.696 2.4 12 2.4C17.304 2.4 21.6 6.696 21.6 12C21.6 17.304 17.304 21.6 12 21.6Z" fill="#D53636"/>
                                        </svg>
                                        <span>{errorFound}</span>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                    <div className={displayedSection > 4 ? "panel panel--submission-buttons panel--submission-buttons--submitted" : "panel panel--submission-buttons"}>
                        <div className="panel__body">
                            {
                                (displayedSection !== 1) && (displayedSection <= 4)
                                &&
                                <button type="button" className="btn btn--submission btn--go-back" onClick={() => handleDisplayedSection('backwards')}>
                                    <span>Back</span>
                                </button>
                            }
                            {
                                (displayedSection !== 4) && (displayedSection <= 4)
                                &&
                                <button type="button" className="btn btn--submission btn--forwards" onClick={() => handleDisplayedSection('forwards')}>
                                    <span>Next</span>
                                </button>
                            }
                            {
                                displayedSection === 4
                                &&
                                <button 
                                    type="button" 
                                    className="btn btn--submission btn--forwards" 
                                    onClick={() => submitArticle()}
                                    disabled={errorFound !== '' ? true : false}
                                >
                                    <span>Submit</span>
                                </button>
                            }

                            {
                                displayedSection > 4
                                &&
                                <div className="change-story-container">
                                    <a href="/" className="btn btn--home btn--grey">
                                        <span>Home</span>
                                    </a>
                                    <span>If you wish to change your story or cancel it contact us.</span>
                                        <a href="mailto:luminous@neahub.co.uk" target="_blank" rel="noreferrer">
                                        <span>Ask for help</span>
                                    </a>
                                </div>
                            }
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}