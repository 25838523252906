import React, { useState, useEffect } from 'react';
// import { MyData } from '../functionalComponents/dataStructure.jsx'

import Sidebar from '../functionalComponents/Sidebar';
import Article from '../functionalComponents/Article';
import FilterBar from '../functionalComponents/FilterBar';
import ArticleSkeleton from '../functionalComponents/ArticleSkeleton';
import HomepageIntroduction from '../functionalComponents/HomepageIntroduction';
import LandingPage from '../functionalComponents/LandingPage';



export default function Home(props) {

    const [articlesList, setArticlesList] = useState([]);
    const [displayedComponent, setDisplayedComponent] = useState("landingPage");
    const [selectedCats, setSelectedCats] = useState([]);
    const [sortingMethod, setSortingMethod] = useState("");

    const [loading, setLoading] = useState(true);



    useEffect(() => {
        setArticlesList(props.articles)
    }, [props])



    useEffect(() => {
        setTimeout(() => {
            fetchArticles();
            setLoading(false);
        }, 2000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    async function fetchArticles() {
        if (props.articles && props.articles.length === 0) {
            try {
                const response = await fetch('https://lumapi.neahub.co.uk/user/');
                const jsonData = await response.json();
                console.log(jsonData)
                setArticlesList(jsonData);
            } catch (err) {
                // setError(err);
            }
        } else {
            setArticlesList(props.articles);
        }
    }



    const toggleComponents = () => {
        if (displayedComponent === 'landingPage') {
            setDisplayedComponent('homePage');
        } else {
            setDisplayedComponent('landingPage');
        }
    }



    function filterArticles (articleTags) {
        return articleTags.some(tag => selectedCats.includes(tag));
    }



    function handleReordering(articlesList) {
        let reorderedArticles = [...articlesList];
        if (sortingMethod === "Relevant") {
            reorderedArticles.sort((a, b) => (b.Frontpage - a.Frontpage));
        } else if (sortingMethod === "Popular") {
            reorderedArticles.sort((a, b) => b.Views - a.Views);
        } else if (sortingMethod === "Oldest") {
            reorderedArticles.sort((a, b) => new Date(a.date) - new Date(b.date));
        } else if (sortingMethod === "Newest") {
            reorderedArticles.sort((a, b) => new Date(b.Date) - new Date(a.Date));
        }
        return reorderedArticles;
    }



    function handleFilterStateChange(newSelectedCats) {
        setSelectedCats(newSelectedCats);
    }



    function handleSortingStateChange(sortingMethod) {
        setSortingMethod(sortingMethod);
    }


    // Handles the sorting that comes from the sidebar
    function handleSidebarSorting(sortingFilter, action) {
        let newSelectedCats = [...selectedCats];
        if (action === "ADD") {
            newSelectedCats.push(sortingFilter);
            handleFilterStateChange(newSelectedCats);
        } else {
            const index = newSelectedCats.indexOf(sortingFilter);
            if (newSelectedCats.indexOf(sortingFilter) > -1) {
                newSelectedCats.splice(index, 1);
                handleFilterStateChange(newSelectedCats)
            }
        }
    }
    // 



    function handleAuthorSorting (author) {
        props.handleAuthorSorting(author);
    }



    let articles;
    if (articlesList !== undefined) {

        articles = [...articlesList];
        if (sortingMethod !== ''){
            articles = handleReordering(articlesList);
        }

        articles = articles.map((article) => {
            let display = true;
            // CASE: NO SELECTED CATEGORIES
            if (selectedCats.length === 0) {
                return (
                    <Article articleInfo={article} key={article.id} />
                )
            // CASE: SELECTED CATEGORIES
            } else{
                display = filterArticles(article.Tags);
                if (display && selectedCats.length > 0) {
                    return (
                        <Article articleInfo={article} key={article.id} />
                    )
                }
            }
        })
    }


    return (
        <div className="page-content page-content--homepage">
            <div className="page-content__cover"></div>
            {
                displayedComponent === 'landingPage' ?
                    <div className="page-content__inner page-content__inner--landing">
                        <HomepageIntroduction toggleComponents={toggleComponents} toggleHomepage={props.toggleHomepage} />
                        <LandingPage toggleNewsletterPopup={props.toggleNewsletterPopup} />
                    </div>
                    :
                    <div className="page-content__inner page-content__inner--homepage">
                        <FilterBar 
                            onStateChange={handleFilterStateChange} 
                            onSortingChange={handleSortingStateChange} 
                        />
                        <div className="page-main">
                            
                            {
                                articlesList !== undefined && articlesList.length > 0 && !loading
                                ?
                                (<>{articles}</>)
                                :
                                (<ArticleSkeleton />)
                            }

                        </div>
                        <div className="page-sidebar">
                            <Sidebar 
                                articlesList={articlesList} 
                                handleSidebarSorting={handleSidebarSorting} 
                                handleAuthorSorting={handleAuthorSorting}
                                selectedCats={selectedCats}
                                toggleNewsletterPopup={props.toggleNewsletterPopup}
                            />
                        </div>
                    </div>
            }
        </div>
    )
}