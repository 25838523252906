import React, { useEffect } from 'react';
// import { MyData } from './dataStructure.js'


export default function HomepageIntroduction(props) {
    const [storiesIlluminated, setStoriesIlluminated] = React.useState({
        numberOfStories: 0,
        nextNumberLastDigit: '',
        previousNumberLastDigit: ''
    });



    useEffect(() => {
        if (props.toggleHomepage === "trigger") {
            props.toggleComponents()
        }
    }, [props.toggleHomepage])



    useEffect(() => {
        const url = "https://lumapi.neahub.co.uk/functions/homescreen/counter";
        const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
        };

        fetch(url, options)
            .then(response => response.json())
            .then(Views => {
                let views = Views.Views;
                handleAdjacentNumbers(views);

                // Save the number of views
                localStorage.setItem("NumberOfViews", views);
            })
            .catch(() => {
                let views = parseInt(localStorage.getItem("NumberOfViews"));

                // If we have previously stored the number of views, we use that number
                if (views) {
                    handleAdjacentNumbers(views);
                    console.log("Case 2");

                // Otherwise just set it to 1000
                } else {
                    handleAdjacentNumbers(1000);
                    console.log("Case 3");
                }
            })
    }, [props.toggleHomepage]);



    function handleAdjacentNumbers(numberOfStories) {
        let nextNumber = numberOfStories + 1;
        let previousNumber = numberOfStories - 1;

        let numbers = {
            numberOfStories: numberOfStories,
            nextNumberLastDigit: nextNumber.toString().slice(-1),
            previousNumberLastDigit: previousNumber.toString().slice(-1)
        };
        setStoriesIlluminated(numbers)
    }



    return (
        <div className="homepage-section">

            <div className="container container--readStories">

                <div className="doodle-homepage-container--mobile doodle-homepage-container--mobile--1">
                    <img src="https://i.imgur.com/vv5jBtP.png" alt="Doodle home" className="doodle-homepage--mobile doodle-homepage--mobile--1" />
                </div>

                <div className="doodle-homepage-container doodle-homepage-container--1">
                    <img src="https://i.imgur.com/MHDrDG4.png" alt="Doodle home" className="doodle-homepage doodle-homepage--1" />
                </div>

                <div className="doodle-homepage-container doodle-homepage-container--2">
                    <img src="https://i.imgur.com/hith0oS.png" alt="Doodle home" className="doodle-homepage doodle-homepage--2" />
                </div>

                <div className="doodle-homepage-container doodle-homepage-container--3">
                    <img src="https://i.imgur.com/03eyY0q.png" alt="Doodle home" className="doodle-homepage doodle-homepage--3" />
                </div>

                <div className="homepage-section__read-stories">
                    <h1>Home for stories of neurodiverse people</h1>
                    <button type="button" className="btn btn--black" onClick={() => props.toggleComponents()}>
                        <span>Read stories</span>
                    </button>
                </div>
            </div>

            <div className="container container--homepageIlluminated">
                <div className="homepage-section__illuminated-stories">
                    <h3>Our stories already illuminated</h3>
                    <div className="stories-counter">
                        <h1 className="stories-counter__prevNumber">{storiesIlluminated.previousNumberLastDigit}</h1>
                        <h1 className="stories-counter__number-of-stories">{storiesIlluminated.numberOfStories}</h1>
                        <h1 className="stories-counter__nextNumber">{storiesIlluminated.nextNumberLastDigit}</h1>
                    </div>
                </div>
            </div>

        </div>
    )
}