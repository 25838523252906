import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function AboutUsDev() {

    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        // Make the Axios POST request to fetch data from the endpoint
        axios
            .post('https://lumapi.neahub.co.uk/functions/frontpage/developers')
            .then((response) => {
                console.log(response.data)
                setTeamMembers(response.data); // Assuming the response data is an array of team members
            })
            .catch((error) => {
                console.error('Error fetching team members:', error);
            });
    }, []);


    return (

        <>
            {
                teamMembers.map((member, index) => (
                    <div key={index} className="team-el">
                        <div className="team-el__avatar-container avatar-container avatar-container--large">
                            <img src={member.Img} alt={member.Name} />
                        </div>
                        <b className="team-el__name">{member.Name}</b>
                        <span className="team-el__position">{member.Position}</span>
                    </div>
                ))
            }
        </>

    );
}