import React from 'react';

import PopularAuthors from './PopularAuthors';

export default function Sidebar(props) {
    const [popularTags, setPopularTags] = React.useState([]);
    const [selectedCats, setSelectedCats] = React.useState([...props.selectedCats]);

    React.useEffect(() => {
        fetchPopularTags();
    }, [])

    React.useEffect(() => {
        setSelectedCats(props.selectedCats);
    }, [props.selectedCats])



    async function fetchPopularTags() {
        const url = 'https://lumapi.neahub.co.uk/functions/tags/popular';
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
        };
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setPopularTags(data.Tags);
            })
            .catch(() => {
                setPopularTags(["Autism", "ADHD", "Neurodiversity"]);
            })
    }



    function handleSorting(category){
        for (const cat of selectedCats) {
            if (cat === category) {
                props.handleSidebarSorting(category, "REMOVE")
                return
            }
        }
        props.handleSidebarSorting(category, "ADD")
    }



    function handleAuthorSorting (author) {
        props.handleAuthorSorting(author);
    }



    function handleTagClass(category) {
        for (const cat of selectedCats) {
            if (cat === category) {
                return "btn btn--black btn--category btn--category--remove";
            }
        }
        return "btn btn--transparent btn--category btn--category--add";
    }



    return (
        <div className="page-sidebar page-sidebar--categories">
            <div className="page-sidebar__inner">

                <div className="page-sidebar__section page-sidebar__section--categories">
                    <div className="page-sidebar__section__heading">Popular categories</div>
                    <div className="page-sidebar__section__body">
                        {
                            popularTags.map((category, index) => (
                                <button 
                                    type="button" 
                                    key={index} 
                                    // "btn btn--transparent btn--category"
                                    className={handleTagClass(category)}
                                    onClick={() => handleSorting(category)}
                                    // onClick={() => props.handleSidebarSorting(category)}
                                >
                                    <span>{category}</span>
                                </button>
                            ))
                        }
                    </div>
                </div>

                <div className="page-sidebar__section page-sidebar__section--authors">
                    <div className="page-sidebar__section__heading">Authors</div>
                    <div className="page-sidebar__section__body">
                        <PopularAuthors handleAuthorSorting={handleAuthorSorting} />
                    </div>
                </div>

                <div className="page-sidebar__section page-sidebar__section--support">
                    <div className="page-sidebar__section__heading">Support us</div>
                    <div className="page-sidebar__section__body">
                        <button onClick={props.toggleNewsletterPopup} type="button" className="btn btn--pink support-option support-option--member">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.39955 1.00202C7.67136 0.548436 8.32864 0.548437 8.60045 1.00202L9.69243 2.82432C9.85094 3.08885 10.1639 3.21847 10.463 3.14351L12.5237 2.6271C13.0367 2.49856 13.5014 2.96333 13.3729 3.47627L12.8565 5.53697C12.7815 5.8361 12.9112 6.14906 13.1757 6.30757L14.998 7.39955C15.4516 7.67136 15.4516 8.32864 14.998 8.60045L13.1757 9.69243C12.9112 9.85094 12.7815 10.1639 12.8565 10.463L13.3729 12.5237C13.5014 13.0367 13.0367 13.5014 12.5237 13.3729L10.463 12.8565C10.1639 12.7815 9.85094 12.9112 9.69243 13.1757L8.60045 14.998C8.32864 15.4516 7.67136 15.4516 7.39955 14.998L6.30757 13.1757C6.14906 12.9112 5.8361 12.7815 5.53697 12.8565L3.47626 13.3729C2.96333 13.5014 2.49856 13.0367 2.6271 12.5237L3.14351 10.463C3.21848 10.1639 3.08884 9.85094 2.82432 9.69243L1.00202 8.60045C0.548436 8.32864 0.548437 7.67136 1.00202 7.39955L2.82432 6.30757C3.08885 6.14906 3.21847 5.8361 3.14351 5.53697L2.6271 3.47626C2.49856 2.96333 2.96333 2.49856 3.47626 2.6271L5.53697 3.14351C5.8361 3.21848 6.14906 3.08884 6.30757 2.82432L7.39955 1.00202Z" fill="white"/>
                            </svg>
                            <span>Become a member</span>
                        </button>
                        <a href="https://www.paypal.com/donate/?hosted_button_id=BNJJR5S4YXWEQ" className="btn btn--black support-option support-option--donate">
                            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.99998 12.2333L6.03331 11.3533C2.59998 8.24 0.333313 6.18667 0.333313 3.66667C0.333313 1.61333 1.94665 0 3.99998 0C5.15998 0 6.27331 0.54 6.99998 1.39333C7.72665 0.54 8.83998 0 9.99998 0C12.0533 0 13.6666 1.61333 13.6666 3.66667C13.6666 6.18667 11.4 8.24 7.96665 11.36L6.99998 12.2333Z" fill="white"/>
                            </svg>
                            <span>Donate</span>
                        </a>
                        <a href="/storysubmission" className="btn btn--grey support-option support-option--submit-article">
                            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.80298 15.4914C4.80298 15.4914 5.38523 7.99885 8.36582 7.99346C5.38523 7.96405 4.83671 0.508423 4.83671 0.508423" fill="black"/>
                                <path d="M4.80353 15.9999C4.79022 15.9999 4.7769 15.9994 4.76349 15.9983C4.48178 15.9767 4.2711 15.7322 4.2928 15.4524C4.29891 15.3739 4.44815 13.5089 4.97329 11.6098C5.46627 9.82706 6.1229 8.61668 6.93448 7.98949C6.12595 7.35946 5.47476 6.15036 4.98966 4.37307C4.47301 2.48003 4.33205 0.623646 4.32633 0.545529C4.30572 0.265601 4.51749 0.0220335 4.7992 0.00145061C5.07982 -0.0196224 5.32611 0.191405 5.34692 0.471235C5.48117 2.28449 6.3248 7.46502 8.37082 7.48521C8.65223 7.48795 8.8785 7.71603 8.87731 7.99567C8.87613 8.2753 8.64799 8.50152 8.36658 8.50201C6.32204 8.50573 5.45542 13.7085 5.31299 15.5309C5.29208 15.7974 5.06798 16 4.80344 16L4.80353 15.9999Z" fill="black"/>
                                <path d="M4.83671 0.508423C4.83671 0.508423 4.68421 8.1396 0.511597 8.21693C4.68421 8.1396 4.80307 15.4915 4.80307 15.4915" fill="black"/>
                                <path d="M4.8027 15.9999C4.52396 15.9999 4.29581 15.7776 4.29137 15.4997C4.29058 15.4572 4.19717 11.2115 2.36195 9.45163C1.83838 8.9496 1.23542 8.71143 0.520996 8.72515C0.238698 8.72907 0.00522773 8.50697 0 8.22636C0 8.22313 0 8.21979 0 8.21656C0 7.94025 0.222919 7.71374 0.502158 7.70865C4.13454 7.64141 4.32372 0.569604 4.3252 0.498152C4.33112 0.217636 4.56893 -0.0057395 4.84709 0.000239348C5.12958 0.00592416 5.35389 0.237926 5.34826 0.51854C5.34264 0.798468 5.19291 6.39742 2.36905 8.18353C2.61741 8.32947 2.85266 8.50893 3.0741 8.72143C5.21431 10.7757 5.31157 15.2922 5.31463 15.4835C5.31917 15.7642 5.09378 15.9955 4.81128 15.9999C4.80842 15.9999 4.80566 15.9999 4.8029 15.9999H4.8027Z" fill="black"/>
                            </svg>
                            <span>Submit an article</span>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}