import React from "react";

import Author from "./Author";

export default function PopularAuthors (props) {
    const [popularAuthors, setPopularAuthors] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [err, setErr] = React.useState('');



    React.useEffect(() => {
        setLoading(true);
        const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
        };
        try {
            fetch('https://lumapi.neahub.co.uk/functions/authors/popular', options)
                .then(response => response.json())
                .then(popularAuthors => {
                    const filteredAuthors = popularAuthors.filter((Author) => Author.Author !== 'Anonymous');
                    setPopularAuthors(filteredAuthors)
                    setLoading(false);
                })
        } catch (err) {
            setErr("An unknown error occurred");
            setLoading(false);
        }
    }, [])



    function handleSelectedAuthor(author) {
        if (props.handleAuthorSorting){
            props.handleAuthorSorting(author.Author);
        }
    }



    let authors;
    if (popularAuthors.length > 0 && !loading) {
        authors = popularAuthors.map((author) => (
            <div 
                className="author-container"
                onClick={() => handleSelectedAuthor(author)}
                key={author.id}
            >
                <Author src={author.Img} name={author.Author} />
            </div>
        ))
    } else {
        authors = <>
            <div className="author-skeleton">
                <div className="author-skeleton__img"></div>
                <div className="author-skeleton__name"></div>
            </div>
            <div className="author-skeleton">
                <div className="author-skeleton__img"></div>
                <div className="author-skeleton__name"></div>
            </div>
            <div className="author-skeleton">
                <div className="author-skeleton__img"></div>
                <div className="author-skeleton__name"></div>
            </div>
        </>
    }

    return (
        <>
            {
                err === '' 
                ?
                (<>{authors}</>)
                :
                <div className="author-err">An unknown error occurred.</div>
            }
        </>
    )
}