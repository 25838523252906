import React from 'react';

import PopularArticles from './PopularArticles';
import PopularAuthors from './PopularAuthors';

export default function LandingPage (props) {

    const [popularTags, setPopularTags] = React.useState([]);

    React.useEffect(() => {
        const url = 'https://lumapi.neahub.co.uk/functions/tags/popular';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        };
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setPopularTags(data.Tags);
            })
            .catch(() => {
                setPopularTags(["Autism", "ADHD", "Neurodiversity"]);
            })
    }, []);



    const tags = popularTags.map((tag, index) => (
        <button key={index} type="button" className="btn btn--grey">
            <span>{tag}</span>
        </button>
    ))



    return (
        <div className="homepage-section">
            <div className="container container--compressedArticles">

                <PopularArticles />

                <div className="panel panel--homepageCategories">
                    <div className="panel__heading">
                        <div className="h3">Popular categories</div>
                    </div>
                    <div className="panel__body">
                        {tags}
                    </div>
                </div>

                <div className="panel panel--homepageCategories">
                    <div className="panel__heading">
                        <div className="h3">Authors</div>
                    </div>
                    <div className="panel__body">
                        <div className="authors authors--container">

                            <PopularAuthors handleAuthorSorting={props.handleAuthorSorting} />

                        </div>
                    </div>
                </div>

                <div className="panel panel--homepageCategories">
                    <div className="panel__heading">
                        <div className="h3">Support us</div>
                    </div>
                    <div className="panel__body">
                        <div className="buttons-container buttons-container--support">

                            <button onClick={props.toggleNewsletterPopup} type="button" className="btn btn--pink">
                                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.39955 0.502024C7.67136 0.0484359 8.32864 0.0484367 8.60045 0.502025L9.69243 2.32432C9.85094 2.58885 10.1639 2.71847 10.463 2.64351L12.5237 2.1271C13.0367 1.99856 13.5014 2.46333 13.3729 2.97627L12.8565 5.03697C12.7815 5.3361 12.9112 5.64906 13.1757 5.80757L14.998 6.89955C15.4516 7.17136 15.4516 7.82864 14.998 8.10045L13.1757 9.19243C12.9112 9.35094 12.7815 9.6639 12.8565 9.96303L13.3729 12.0237C13.5014 12.5367 13.0367 13.0014 12.5237 12.8729L10.463 12.3565C10.1639 12.2815 9.85094 12.4112 9.69243 12.6757L8.60045 14.498C8.32864 14.9516 7.67136 14.9516 7.39955 14.498L6.30757 12.6757C6.14906 12.4112 5.8361 12.2815 5.53697 12.3565L3.47626 12.8729C2.96333 13.0014 2.49856 12.5367 2.6271 12.0237L3.14351 9.96303C3.21848 9.6639 3.08885 9.35094 2.82432 9.19243L1.00202 8.10045C0.548436 7.82864 0.548437 7.17136 1.00202 6.89955L2.82432 5.80757C3.08885 5.64906 3.21847 5.3361 3.14351 5.03697L2.6271 2.97626C2.49856 2.46333 2.96333 1.99856 3.47626 2.1271L5.53697 2.64351C5.8361 2.71848 6.14906 2.58885 6.30757 2.32432L7.39955 0.502024Z" fill="white"/>
                                </svg>
                                <span>Become a member</span>
                            </button>
                            <a href="https://www.paypal.com/donate/?hosted_button_id=BNJJR5S4YXWEQ" className="btn btn--black" target="_blank" rel="noreferrer">
                                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.00016 12.7333L6.0335 11.8533C2.60016 8.74 0.333496 6.68667 0.333496 4.16667C0.333496 2.11333 1.94683 0.5 4.00016 0.5C5.16016 0.5 6.2735 1.04 7.00016 1.89333C7.72683 1.04 8.84016 0.5 10.0002 0.5C12.0535 0.5 13.6668 2.11333 13.6668 4.16667C13.6668 6.68667 11.4002 8.74 7.96683 11.86L7.00016 12.7333Z" fill="white"/>
                                </svg>
                                <span>Donate</span>
                            </a>
                            <a href="/storysubmission" className="btn btn--grey">
                                <svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.80273 15.9915C4.80273 15.9915 5.38499 8.49897 8.36558 8.49358C5.38499 8.46417 4.83647 1.00854 4.83647 1.00854" fill="black"/>
                                    <path d="M4.80329 16.4999C4.78997 16.4999 4.77666 16.4994 4.76325 16.4983C4.48154 16.4767 4.27085 16.2322 4.29255 15.9524C4.29867 15.8739 4.4479 14.0089 4.97304 12.1098C5.46603 10.3271 6.12265 9.11668 6.93423 8.48949C6.12571 7.85946 5.47451 6.65036 4.98942 4.87307C4.47276 2.98003 4.33181 1.12365 4.32609 1.04553C4.30547 0.765601 4.51725 0.522034 4.79895 0.501451C5.07957 0.480378 5.32587 0.691405 5.34668 0.971235C5.48092 2.78449 6.32456 7.96502 8.37057 7.98521C8.65198 7.98795 8.87825 8.21603 8.87707 8.49567C8.87589 8.7753 8.64774 9.00152 8.36633 9.00201C6.3218 9.00573 5.45518 14.2085 5.31275 16.0309C5.29184 16.2974 5.06774 16.5 4.80319 16.5L4.80329 16.4999Z" fill="black"/>
                                    <path d="M4.83683 1.00854C4.83683 1.00854 4.68433 8.63972 0.511719 8.71705C4.68433 8.63972 4.80319 15.9916 4.80319 15.9916" fill="black"/>
                                    <path d="M4.8027 16.5001C4.52396 16.5001 4.29581 16.2778 4.29137 15.9998C4.29058 15.9574 4.19717 11.7116 2.36195 9.95175C1.83838 9.44973 1.23542 9.21155 0.520996 9.22527C0.238698 9.22919 0.00522773 9.0071 0 8.72648C0 8.72325 0 8.71991 0 8.71668C0 8.44038 0.222919 8.21386 0.502158 8.20877C4.13454 8.14153 4.32372 1.06973 4.3252 0.998274C4.33112 0.717758 4.56893 0.494383 4.84709 0.500361C5.12958 0.506046 5.35389 0.738048 5.34826 1.01866C5.34264 1.29859 5.19291 6.89754 2.36905 8.68365C2.61741 8.82959 2.85266 9.00905 3.0741 9.22155C5.21431 11.2758 5.31157 15.7923 5.31463 15.9836C5.31917 16.2643 5.09378 16.4957 4.81128 16.5001C4.80842 16.5001 4.80566 16.5001 4.8029 16.5001H4.8027Z" fill="black"/>
                                </svg>
                                <span>Submit an article</span>
                            </a>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}