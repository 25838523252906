import React from 'react';
import axios from 'axios';

export default function PopupNewsletter ({isPopupVisible, toggleNewsletterPopup}) {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState(false);
    const [email, setEmail] = React.useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            // Perform the asynchronous request
            const response = await axios.post('https://lumapi.neahub.co.uk/mail/newsletter/subscribe', { email: email });
      
            // Reset the form fields
            setEmail('');
            setTimeout(() => {
                setSuccess(true);
                setLoading(false);
            }, 350);

        } catch (error) {
            console.error('Form submission failed!', error);
            setError("Something went wrong, please try again later.")
        }
    }

    return (
        <div className={isPopupVisible ? 'modal modal--newsLetter modal--open' : 'modal modal--newsLetter modal--closed'}>
            <div className="modal__wrap" onClick={toggleNewsletterPopup}></div>
            <div className={loading ? "modal__dialogue modal__dialogue--loading" : "modal__dialogue"}>

                <button type="button" className="btn btn--close btn--transparent" onClick={toggleNewsletterPopup}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black"/>
                    </svg>
                </button>

                <div className="loading-el"></div>
                {
                    !success 
                    ?
                    <>
                    <button type="button" className="btn btn--grey btn--back" onClick={toggleNewsletterPopup}>
                        <svg width="24" height="40" viewBox="0 0 24 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.34 3.54L19.78 0L0 19.8L19.8 39.6L23.34 36.06L7.08 19.8L23.34 3.54Z" fill="black"/>
                        </svg>
                    </button>

                    <div className="modal__heading">
                        <h1 className="modal__title">Get our stories to your email</h1>
                        <h6 className="modal__subtitle">Enter your email to subscribe to our newsletter</h6>
                    </div>
                    <div className="modal__body">

                        <form onSubmit={handleSubmit} className="form form--email">
                            {
                                error !== '' &&
                                <div className="form__error">{error}</div>
                            }
                            <div className="form__input-container">
                                <input 
                                    placeholder="Your email" 
                                    value={email}
                                    name="email"
                                    type="email" 
                                    className="input" 
                                    required 
                                    onChange={handleEmailChange}
                                />
                            </div>
                            <div className="form__buttons">
                                <button type="submit" className={!loading ? "btn btn--pink" : "btn btn--pink btn--loading"}>
                                    <span>Subscribe</span>
                                </button>
                                <button type="submit" className="btn btn--transparent" onClick={toggleNewsletterPopup}>
                                    <span>Cancel</span>
                                </button>
                            </div>
                        </form>

                    </div>
                    </>
                    :
                    <>
                        <img src="https://i.imgur.com/fcHp37S.png" className="modal-doodle modal-doodle--1" alt="Doodle 1" />
                        <img src="https://i.imgur.com/uyTDCL8.png" className="modal-doodle modal-doodle--2" alt="Doodle 2" />
                        <img src="https://i.imgur.com/3LcTavO.png" className="modal-doodle modal-doodle--3" alt="Doodle 3" />
                        <img src="https://i.imgur.com/Jepzemt.png" className="modal-doodle modal-doodle--4" alt="Doodle 4" />

                        <img src="https://i.imgur.com/Cm0HeZT.png" className="modal-doodle-mobile modal-doodle-mobile--1" alt="Doodle" />

                        <div className="modal__heading">
                            <h1 className="modal__title">You are part of our family now!</h1>
                            <h6 className="modal__subtitle">Next newsletter will reach your box upon it’s release</h6>
                        </div>

                        <div className="modal__body">
                            <button onClick={toggleNewsletterPopup} type="button" className="btn btn--grey btn--home">
                                <span>Close</span>
                            </button>
                        </div>
                    </>
                }
                

            </div>
        </div>
    )
}