import React from "react";

export default function Select(props) {
    const [options, setOptions] = React.useState(props.optionsList);
    const [selectedValues, setSelectedValues] = React.useState([]);
    const [error, setError] = React.useState('Please select at least 1 category');



    React.useEffect(() => {
        if (props.selectOptions.length > 0){
            setSelectedValues(props.selectOptions);
        }
    }, [props.selectOptions])



    React.useEffect(() => {
        setOptions(props.optionsList);
    }, [props.optionsList])



    const optionsList = options.map((option, index) => (
        <option 
            key={index} 
            value={option}
            disabled={handleDisabledOption(option)}
        >
            {option}
        </option>
    ))



    function handleFormGroupClass() {
        if (selectedValues.length === 0) {
            return "form-group form-group--select form-group--fail";
        } else {
            return "form-group form-group--select form-group--success";
        }
    }



    function handleErrorCheck(selectedValuesNew) {
        if (selectedValuesNew.length > 0) {
            setError('');
        } else {
            setError('Please select at least 1 category');
        }
    }



    function handleSelectChange (e) {
        let selectedValuesNew = [...selectedValues];
        selectedValuesNew.push(e.target.value);
        setSelectedValues(selectedValuesNew);
        
        handleErrorCheck(selectedValuesNew);

        if (selectedValuesNew.length > 0){
            props.extractInfo(selectedValuesNew, "Categories");
        }
    }



    function removeCategory(catName) {
        let newSelectedValues = [...selectedValues];
        newSelectedValues = newSelectedValues.filter((item) => item !== catName);
        setSelectedValues(newSelectedValues)
        handleErrorCheck(newSelectedValues);
        props.extractInfo(newSelectedValues, "Categories");
    }



    function handleDisabledOption(option) {
        if (selectedValues.includes(option)) {
            return true;
        } else{
            return false;
        }
    }



    return(
        <div className={handleFormGroupClass()}>
            <div className="form-group__info">
                <small className="form-group__small">{props.placeholder}</small>
            </div>
            <div className="form-group__select-container">
                <div className="form-group__select-list">
                    <div className="form-group__select-container">

                        <div className="select-container__inner">
                            <select onChange={handleSelectChange}>
                                <option value="">{props.placeholder}</option>
                                {optionsList}
                            </select>
                            <svg className="svg-dd" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.11508 0.295078L0.705078 1.70508L6.70508 7.70508L12.7051 1.70508L11.2951 0.295078L6.70508 4.87508L2.11508 0.295078Z" fill="#5E6368"/>
                            </svg>
                        </div>

                    </div>
                </div>
                <div className="form-group__select-additional">
                    <div className="form-group__error">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8 15.6H13.2V18H10.8V15.6ZM10.8 6H13.2V13.2H10.8V6ZM11.988 0C5.364 0 0 5.376 0 12C0 18.624 5.364 24 11.988 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 11.988 0ZM12 21.6C6.696 21.6 2.4 17.304 2.4 12C2.4 6.696 6.696 2.4 12 2.4C17.304 2.4 21.6 6.696 21.6 12C21.6 17.304 17.304 21.6 12 21.6Z" fill="#D53636"/>
                        </svg>
                        <div className="error-message">{error}</div>
                    </div>
                    <div className="select-additional__category">
                        {
                            selectedValues.map((cat, index) => (
                                <button 
                                    onClick={() => removeCategory(cat)} 
                                    className="btn btn--black select-additional__category-btn" key={index}
                                >
                                    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.66658 1.77337L8.72658 0.833374L4.99992 4.56004L1.27325 0.833374L0.333252 1.77337L4.05992 5.50004L0.333252 9.22671L1.27325 10.1667L4.99992 6.44004L8.72658 10.1667L9.66658 9.22671L5.93992 5.50004L9.66658 1.77337Z" fill="#F5F5F5"/>
                                    </svg>
                                    <span>{cat}</span>
                                </button>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

// PROPS:
// placeholder
// options