import React from 'react';

// Some hardcoded categories if the request to the back end fails
let categories = ["Autism spectrum", "Neurodiversity", "Alzheimer’s", "Parkinson’s", "Society", "Tourette Syndrome", "Daily stories", "Brain Injury", "ADHD", "Paralysis", "Children", "Dementia", "Adults"]
const sortingMethods = ["Relevant", "Popular", "Oldest", "Newest"];

export default function FilterBar({onStateChange, onSortingChange}) {

    const [sorting, handleSorting] = React.useState("");
    const [selectedCats, setSelectedCats] = React.useState([]);
    const [toggledMenu, handleToggledMenu] = React.useState('');



    React.useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const url = "https://lumapi.neahub.co.uk/functions/tags";
        
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                categories = data.Tags;
            })
    }, [])



    function handleSelectedCats(catName) {
        let newSelectedCats = [...selectedCats];

        if (selectedCats.includes(catName)){
            newSelectedCats = newSelectedCats.filter(cat => cat !== catName);
        } else {
            newSelectedCats.push(catName);
        }

        setSelectedCats(newSelectedCats);

        // Sends the selected categories to the Home component
        onStateChange(newSelectedCats);
    }



    function handleSortingMethod(sortingMethod) {
        if (sortingMethod === "" || sortingMethod !== sorting){
            handleSorting(sortingMethod);
        } else {
            handleSorting("");
        }
        onSortingChange(sortingMethod);
    }



    function handleMenuLinkClass(catName) {
        if (selectedCats.includes(catName)) {
            return "menu-link menu-link--active";
        } else{
            return "menu-link";
        }
    }



    function handleMenu(menu) {
        if (toggledMenu === menu) {
            handleToggledMenu('');
        } else {
            handleToggledMenu(menu);
        }
    }



    const menuLinks = categories.map((category, index) => {
        return (
            <button key={index} type="button" className={handleMenuLinkClass(category)} onClick={() => handleSelectedCats(category)}>
                {category}
            </button>
        )
    });

    return (
        <div className="filterbar">
            <div className="filterbar__inner">

                <div className={toggledMenu === 'sortMenu' ? "menu menu--container menu--sort menu--open" : "menu menu--container menu--sort"}>
                    <button
                        type="button"
                        className="btn btn--transparent menu__toggle"
                        onClick={() => handleMenu('sortMenu')}
                    >
                        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 8.5H4V7.16667H0V8.5ZM0 0.5V1.83333H12V0.5H0ZM0 5.16667H8V3.83333H0V5.16667Z" fill="black" />
                        </svg>
                        <span>Sort</span>
                    </button>
                    <div className="menu__dd">
                        <div className="menu__dd__body">
                            {
                                sortingMethods.map(method => (
                                    <button key={method} type="button" className="menu-link" onClick={() => handleSortingMethod(method)}>
                                        <span>{method}</span>
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className={toggledMenu === 'categoryMenu' ? "menu menu--container menu--category menu--open" : "menu menu--container menu--category"}>
                    <button
                        type="button"
                        className="btn btn--transparent menu__toggle"
                        onClick={() => handleMenu('categoryMenu')}
                    >
                        <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 0.833374L2.33333 6.83337H9.66667L6 0.833374ZM6 3.39337L7.28667 5.50004H4.70667L6 3.39337ZM9.66667 8.16671C8.00667 8.16671 6.66667 9.50671 6.66667 11.1667C6.66667 12.8267 8.00667 14.1667 9.66667 14.1667C11.3267 14.1667 12.6667 12.8267 12.6667 11.1667C12.6667 9.50671 11.3267 8.16671 9.66667 8.16671ZM9.66667 12.8334C8.74667 12.8334 8 12.0867 8 11.1667C8 10.2467 8.74667 9.50004 9.66667 9.50004C10.5867 9.50004 11.3333 10.2467 11.3333 11.1667C11.3333 12.0867 10.5867 12.8334 9.66667 12.8334ZM0 13.8334H5.33333V8.50004H0V13.8334ZM1.33333 9.83337H4V12.5H1.33333V9.83337Z" fill="black" />
                        </svg>
                        <span>Category</span>
                        <b>{selectedCats.length}</b>
                    </button>
                    <div className="menu__dd">
                        <div className="menu__dd__body">
                            {menuLinks}
                        </div>
                    </div>
                </div>

                <div className="selectedCats-container">
                    {
                        selectedCats.map((cat, index) => (
                            <button 
                                type="button" 
                                className="btn btn--black" 
                                key={index} 
                                onClick={() => handleSelectedCats(cat)}
                            >
                                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.66658 1.77337L8.72658 0.833374L4.99992 4.56004L1.27325 0.833374L0.333252 1.77337L4.05992 5.50004L0.333252 9.22671L1.27325 10.1667L4.99992 6.44004L8.72658 10.1667L9.66658 9.22671L5.93992 5.50004L9.66658 1.77337Z" fill="#F5F5F5"/>
                                </svg>
                                <span>{cat}</span>
                            </button>
                        ))
                    }
                </div>

                {/* TEMPORARY */}
                {/* <div className="filter-tags">
                    <div className="filter-tag">
                        <button type="button" className="btn btn--black filter-tag__button">
                            <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.66658 1.77337L8.72658 0.833374L4.99992 4.56004L1.27325 0.833374L0.333252 1.77337L4.05992 5.50004L0.333252 9.22671L1.27325 10.1667L4.99992 6.44004L8.72658 10.1667L9.66658 9.22671L5.93992 5.50004L9.66658 1.77337Z" fill="#F5F5F5" />
                            </svg>
                        </button>
                        <span>Filtwer tag</span>
                    </div>
                    <div className="filter-tag">
                        <button type="button" className="btn btn--black filter-tag__button">
                            <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.66658 1.77337L8.72658 0.833374L4.99992 4.56004L1.27325 0.833374L0.333252 1.77337L4.05992 5.50004L0.333252 9.22671L1.27325 10.1667L4.99992 6.44004L8.72658 10.1667L9.66658 9.22671L5.93992 5.50004L9.66658 1.77337Z" fill="#F5F5F5" />
                            </svg>
                        </button>
                        <span>Filter tag</span>
                    </div>
                    <div className="filter-tag">
                        <button type="button" className="btn btn--black filter-tag__button">
                            <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.66658 1.77337L8.72658 0.833374L4.99992 4.56004L1.27325 0.833374L0.333252 1.77337L4.05992 5.50004L0.333252 9.22671L1.27325 10.1667L4.99992 6.44004L8.72658 10.1667L9.66658 9.22671L5.93992 5.50004L9.66658 1.77337Z" fill="#F5F5F5" />
                            </svg>
                        </button>
                        <span>Filter tag</span>
                    </div>
                </div> */}
                {/*  */}

            </div>
        </div>
    )
}