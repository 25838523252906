import React, { useState, useEffect } from 'react';
import { ListSeparator } from 'sass';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { MyData } from '../functionalComponents/dataStructure.jsx'



export default function Register() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPass] = useState('');
    const token = localStorage.getItem('token');


    async function testAuth() {
        try {
            axios.post('https://lumapi.neahub.co.uk/admin', {
                headers: {
                    "Authorization": token,
                },
            })
                .then((response) => {
                    console.log(response);
                    // Handle the response
                })
                .catch((error) => {
                    console.log('ACCESS DENIED. User not logged in.');
                    navigate("/");
                    // Handle errors
                });

        } catch (error) {
            console.log(error)
            navigate("/");
        }
    }


    const handleSubmitRegister = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://lumapi.neahub.co.uk/admin/register', {
                headers: {
                    "Authorization": token,
                },
                body: {
                    email, password
                }
            },
            )
        } catch (error) {
            console.log('ACCESS DENIED, ADMIN PRIVILEGES REQUIRED.')
            navigate("/");
        }
    }

    React.useEffect(() => {
        testAuth()
    }, [])

    return (

        <div className="page-content page-content--login">
            <h2>Register</h2>
            <form className="login-form" onSubmit={handleSubmitRegister}>
                <label htmlFor="email">email</label>
                <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="youremail@gmail.com" id="email" name="email" />
                <label htmlFor="password">password</label>
                <input value={password} onChange={(e) => setPass(e.target.value)} type="password" placeholder="********" id="password" name="password" />
                <button type="submit">Register</button>
            </form>

            <h2>
                <a href='/login' className="btn--login">Login</a>
            </h2>




        </div>
    )
}