import React, { useState, useEffect } from 'react';
import { ListSeparator } from 'sass';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { MyData } from '../functionalComponents/dataStructure.jsx'



export default function Login() {
    const token = localStorage.getItem('token');

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPass] = useState('');

    const handleSubmitLogin = (e) => {
        e.preventDefault();
        try {
            axios.post('https://lumapi.neahub.co.uk/auth/login', { email, password })
                .then((response) => {
                    const { token } = response.data;
                    // Save the token to local storage
                    localStorage.setItem('token', token);
                    navigate('/congratulations');
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        console.log('Unauthorized request');
                        navigate('/noaccount');
                    } else {
                        console.log('Other error occurred');
                        console.log(error);
                        navigate('/');
                    }
                });
        } catch (error) {
            console.log('Error caught during request');
            console.log(error);
            navigate('/');
        }
    }

    const TestAuth = (e) => {
        const token = localStorage.getItem('token');
        e.preventDefault();
        try {
            axios.post('https://lumapi.neahub.co.uk/admin', {
                headers: {
                    "Authorization": token,
                },
            })
                .then((response) => {
                    console.log(response);

                    // Handle the response
                })
                .catch((error) => {
                    navigate("/notokens");

                    // Handle errors
                });

        } catch (error) {
            console.log(error)
            navigate("/");
        }
    }
    const handleLogout = () => {
        // Remove the token from local storage
        localStorage.removeItem('token');

        // Perform any additional logout logic or redirect the user
        // For example, you can reset the user state, clear any cached data, or redirect to the login page.
    };



    return (

        <div className="page-content page-content--login">
            <h2>Login</h2>
            <form className="login-form " onSubmit={handleSubmitLogin}>
                <label htmlFor="email">email</label>
                <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="yourmail@gmail.com" id="email" name="email" />
                <label htmlFor="password">password</label>
                <input value={password} onChange={(e) => setPass(e.target.value)} type="password" placeholder="********" id="password" name="password" />
                <button type="submit">Log In</button>
            </form>

            {/* <h2>Login Test Button.</h2>
            <form className="login-form" onSubmit={TestAuth}>
                <button type="submit">Test Auth</button>
            </form>

            <h2>Logout</h2>
            <form className="login-form" onSubmit={handleLogout}>
                <button type="submit"> Logout</button>
            </form> */}



        </div>
    )
}