import React from 'react';

export default function PopupMobileMenu ({isPopupVisible, toggleMobileMenu}) {

    const links = [
        {
            name: "Home",
            link: "/",
            icon: <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M220-180h150v-250h220v250h150v-390L480-765 220-570v390Zm-60 60v-480l320-240 320 240v480H530v-250H430v250H160Zm320-353Z"/></svg>
        },
        {
            name: "About Us",
            link: "/aboutus",
            icon: <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M38-160v-94q0-35 18-63.5t50-42.5q73-32 131.5-46T358-420q62 0 120 14t131 46q32 14 50.5 42.5T678-254v94H38Zm700 0v-94q0-63-32-103.5T622-423q69 8 130 23.5t99 35.5q33 19 52 47t19 63v94H738ZM358-481q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm360-150q0 66-42 108t-108 42q-11 0-24.5-1.5T519-488q24-25 36.5-61.5T568-631q0-45-12.5-79.5T519-774q11-3 24.5-5t24.5-2q66 0 108 42t42 108ZM98-220h520v-34q0-16-9.5-31T585-306q-72-32-121-43t-106-11q-57 0-106.5 11T130-306q-14 6-23 21t-9 31v34Zm260-321q39 0 64.5-25.5T448-631q0-39-25.5-64.5T358-721q-39 0-64.5 25.5T268-631q0 39 25.5 64.5T358-541Zm0 321Zm0-411Z"/></svg>
        },
        {
            name: "Submit an Article",
            link: "/storysubmission",
            icon: <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M140-120q-24 0-42-18t-18-42v-489h60v489h614v60H140Zm169-171q-24 0-42-18t-18-42v-489h671v489q0 24-18 42t-42 18H309Zm0-60h551v-429H309v429Zm86-131h168v-215H395v215Zm211 0h168v-88H606v88Zm0-127h168v-88H606v88ZM309-351v-429 429Z"/></svg>
        },
        {
            name: "Become a Member",
            link: "/",
            icon: <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M730-400v-130H600v-60h130v-130h60v130h130v60H790v130h-60Zm-370-81q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM40-160v-94q0-35 17.5-63.5T108-360q75-33 133.338-46.5 58.339-13.5 118.5-13.5Q420-420 478-406.5 536-393 611-360q33 15 51 43t18 63v94H40Zm60-60h520v-34q0-16-9-30.5T587-306q-71-33-120-43.5T360-360q-58 0-107.5 10.5T132-306q-15 7-23.5 21.5T100-254v34Zm260-321q39 0 64.5-25.5T450-631q0-39-25.5-64.5T360-721q-39 0-64.5 25.5T270-631q0 39 25.5 64.5T360-541Zm0-90Zm0 411Z"/></svg>
        }
    ]

    function handleActiveLink (name) {
        return "mobile-menu__link";
    }

    return (
        <div className={isPopupVisible ? 'modal modal--mobile-menu modal--side-left modal--open' : 'modal modal--mobile-menu modal--side-left modal--closed'}>
            <div className="modal__wrap" onClick={toggleMobileMenu}></div>
            <div className="modal__dialogue">

                <div className="mobile-menu__doodle-container mobile-menu__doodle-container--1">
                    <img className="mobile-menu__doodle" src="https://i.imgur.com/NXkrFHX.png" alt="Doodle" />
                </div>

                <div className="mobile-menu__doodle-container mobile-menu__doodle-container--2">
                    <img className="mobile-menu__doodle" src="https://i.imgur.com/0dsMVcy.png" alt="Doodle" />
                </div>

                <div className="mobile-menu__doodle-container mobile-menu__doodle-container--3">
                    <img className="mobile-menu__doodle" src="https://i.imgur.com/j0flOM7.png" alt="Doodle" />
                </div>

                <div className="menu__header">
                    <h2>Menu</h2>
                    <button onClick={toggleMobileMenu} type="button" className="btn btn--close">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black"/>
                        </svg>
                    </button>
                </div>
                <div className="menu__body">
                    {
                        links.map((link, index) => (
                            <a href={link.link} key={index} className={handleActiveLink(link.name)}>
                                {link.icon}
                                <span>{link.name}</span>
                            </a>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}