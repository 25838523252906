export default function Footer() {
    return (
        <div id="ndzn-footer">
            <div className="container">
                <div className="footer__mobile-links">
                    <a href="/aboutus" className="footer__mobile-link">
                        <span>About</span>
                    </a>
                    <a href="/" className="footer__mobile-link">
                        <span>Help</span>
                    </a>
                </div>
                <span>Powered by NEA Studios</span>
            </div>
        </div>
    )
}