// import * as React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import searchBar from './searchBar';

import './scss/vars.scss';
import './scss/buttons.scss';
import './scss/index.scss';
import './scss/header.scss';
import './scss/homepage.scss';
import './scss/articleCompressed.scss';
import './scss/article.scss';
import './scss/aboutus.scss';
import './scss/sidebar.scss'
import './scss/modal.scss'
import './scss/storySubmission.scss';
import './scss/assessUserAndArticle.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
