import AboutUsDev from "./AboutUsDevelopers.jsx";
import AboutUsPartners from "./AboutUsPartners.jsx";

export default function AboutUsPage(props) {
  return (
    <div className="page-content page-content--aboutus">
      <div className="aboutus-section aboutus-section--top">
        <div className="container container--aboutus">
          <img
            src="https://i.imgur.com/U8mcIHv.png"
            className="doodle doodle--1"
            alt="Doodle"
          />
          <img
            src="https://i.imgur.com/v7XPtUD.png"
            className="doodle doodle--2"
            alt="Doodle"
          />
          <img
            src="https://i.imgur.com/v7XPtUD.png"
            className="doodle doodle--2"
            alt="Doodle"
          />

          <img
            src="https://i.imgur.com/cgTCTud.png"
            className="doodle-mobile doodle-mobile--4"
            alt="Doodle"
          />
          <img
            src="https://i.imgur.com/dWR2dY9.png"
            className="doodle-mobile doodle-mobile--5"
            alt="Doodle"
          />
          <img
            src="https://i.imgur.com/SV47GG8.png"
            className="doodle-mobile doodle-mobile--6"
            alt="Doodle"
          />

          <h1>
            <img
              src="https://i.imgur.com/YGp42jo.png"
              className="doodle doodle--3"
              alt="Doodle"
            />
            <img
              src="https://i.imgur.com/LzmxHXY.png"
              className="doodle doodle--4"
              alt="Doodle"
            />
            <img
              src="https://i.imgur.com/QWIi7S4.png"
              className="doodle-mobile doodle-mobile--1"
              alt="Doodle"
            />
            <img
              src="https://i.imgur.com/MOLAUBn.png"
              className="doodle-mobile doodle-mobile--2"
              alt="Doodle"
            />
            <img
              src="https://i.imgur.com/yfsks05.png"
              className="doodle-mobile doodle-mobile--3"
              alt="Doodle"
            />
            <span>
              We aim to inspire hope across the spectrum of neurodiversity
            </span>
          </h1>
        </div>
      </div>

      <div className="aboutus-section aboutus-section--mission aboutus-section--grey">
        <div className="container container--aboutus">
          <img
            src="https://i.imgur.com/rvvrXZU.png"
            className="doodle doodle--5"
            alt="Doodle"
          />
          <img
            src="https://i.imgur.com/XWOCoos.png"
            className="doodle doodle--6"
            alt="Doodle"
          />
          <h2>Mission</h2>
          <p>
            Luminous is a project aimed at giving neurodiverse people, who may
            or may not have received a diagnosis, a platform to share their
            stories and experiences, with an emphasis on the experiences of
            neurodiverse people of colour (PoC) and neurodiverse individuals
            from non-WEIRD (White, Educated, Industrial, Rich, and Democratic)
            countries. We aim to raise awareness by:
          </p>
          <ul>
            <li>
              <span>Educating people on what neurodiversity is</span>
            </li>
            <li>
              <span>
                Emphasizing the research gap in studies on neurodiverse PoC
              </span>
            </li>
            <li>
              <span>
                Building a website to connect neurodiverse PoC around the world
              </span>
            </li>
            <li>
              <span>
                Sharing the success stories of neurodiverse PoC to reduce the
                stigma around neurodiversity among PoC communities.
              </span>
            </li>
          </ul>
          <a href="/storysubmission" className="btn btn--pink btn--share-story">
            <span>Share your story</span>
          </a>
        </div>
      </div>

      <div className="aboutus-section aboutus-section--founder">
        <div className="container container--aboutus">
          <img
            src="https://i.imgur.com/6ejjdzN.png"
            className="doodle doodle--7"
            alt="Doodle"
          />
          <img
            src="https://i.imgur.com/fmyqttP.png"
            className="doodle doodle--8"
            alt="Doodle"
          />
          <img
            src="https://i.imgur.com/0vCGMJO.png"
            className="doodle doodle--9"
            alt="Doodle"
          />

          <h2>The founder</h2>
          <div className="avatar-container avatar-container--large">
            <img src="https://i.imgur.com/tVM7n0Z.png" alt="Founder" />
          </div>
          <p>
            My name is John Lim, and I'm a Psychology student from Malaysia.
            When I was in high school, I volunteered to be a part of a project
            that aims to support autistic children in Malaysia. It was then when
            I realized that the autistic community in Malaysia faces serious
            discrimination and that our society still holds a negative view of
            autism.
          </p>
          <p>
            I've also made a few neurodiverse friends from all around the world,
            and they've expressed how they found it hard to relate to the
            stories they see online about neurodiversity as the representation
            they see was predominantly White. That is why I want to provide a
            platform for these individuals to get their stories out there, to
            show the world that their experiences deserve to be represented, to
            prove that being neurodivergent isn't entirely a bad thing at all.
            in five years - I hope to become a researcher in Psychology in five
            years. While I'm still unsure about which niche I want to specialize
            in, I definitely do hope that I can help others through my research.
          </p>
          <p>
            I have been applying for research opportunities, and I'm also
            looking for internships in clinical psychology. Maybe, this project
            might even encourage me to do research on how neurodiversity
            manifests differently in different cultures.
          </p>
          <a
            className="btn btn--get-in-touch"
            href="mailto:limyuehan@hotmail.com"
          >
            <span>Get in touch with John</span>
          </a>
        </div>
      </div>

      <div className="aboutus-section aboutus-section--team aboutus-section--grey">
        <div className="container container--aboutus">
          <img
            src="https://i.imgur.com/zaEnTEN.png"
            className="doodle doodle--10"
            alt="Doodle"
          />
          <img
            src="https://i.imgur.com/56w3ycf.png"
            className="doodle doodle--11"
            alt="Doodle"
          />

          <h2>Luminous team</h2>

          <div className="team-container">
            <div className="team-el">
              <div className="team-el__avatar-container avatar-container avatar-container--large">
                <img src="https://i.imgur.com/tVM7n0Z.png" alt="John Lim" />
              </div>
              <b className="team-el__name">John Lim</b>
              <span className="team-el__position">Founder</span>
            </div>
            <AboutUsDev />
          </div>

          <a
            className="btn btn--pink btn--join-team"
            href="mailto:limyuehan@hotmail.com"
          >
            <span>Join team</span>
          </a>
        </div>
      </div>

      <div className="aboutus-section aboutus-section--partners">
        <div className="container container--aboutus">
          <h2>Partners</h2>

          <div className="partners-container">
            <AboutUsPartners />

            <div className="partner-el">
              <div className="partner-el__avatar-container avatar-container avatar-container--large">
                <img src="https://i.imgur.com/N2UjBzB.png" alt="Partner" />
              </div>
              <b className="partner-el__name">Nea studio</b>
              <span className="partner-el__position">Co-founding partners</span>
            </div>
          </div>
        </div>
      </div>

      <div className="aboutus-section aboutus-section--bottom aboutus-section--grey">
        <div className="container container--aboutus">
          <h1>
            <img
              src="https://i.imgur.com/C4ZMVoX.png"
              className="doodle doodle--12"
              alt="doodle"
            />
            <span>
              Our team works hard to keep this platform free for everybody
            </span>
          </h1>

          <div className="contributions-container">
            <div className="contribution contribution--free-access">
              <img src="https://i.imgur.com/XWOCoos.png" alt="doodle" />
              <span>Free access</span>
            </div>
            <div className="contribution contribution--research">
              <img src="https://i.imgur.com/L0h6KdC.png" alt="doodle" />
              <span>Contributing to the research</span>
            </div>
            <div className="contribution contribution--education">
              <img src="https://i.imgur.com/trizBCU.png" alt="doodle" />
              <span>Educating public</span>
            </div>
          </div>

          <div className="buttons-container">
            <button
              type="button"
              className="btn btn--pink btn--subs-to-news"
              onClick={props.toggleNewsletterPopup}
            >
              {/* <button type="button" className="btn btn--pink btn--subs-to-news" onClick={props.toggleNewsletterPopup}> */}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.3996 1.18513C11.6714 0.731542 12.3286 0.731542 12.6004 1.18513L14.6721 4.64229C14.8306 4.90682 15.1436 5.03645 15.4427 4.96149L19.3522 3.98178C19.8651 3.85324 20.3299 4.31801 20.2013 4.83094L19.2216 8.7404C19.1467 9.03954 19.2763 9.35249 19.5408 9.51101L22.998 11.5827C23.4516 11.8545 23.4516 12.5117 22.998 12.7836L19.5408 14.8552C19.2763 15.0137 19.1467 15.3267 19.2216 15.6258L20.2013 19.5353C20.3299 20.0482 19.8651 20.513 19.3522 20.3844L15.4427 19.4047C15.1436 19.3298 14.8306 19.4594 14.6721 19.7239L12.6004 23.1811C12.3286 23.6347 11.6714 23.6347 11.3996 23.1811L9.3279 19.7239C9.16939 19.4594 8.85643 19.3298 8.5573 19.4047L4.64784 20.3844C4.13491 20.513 3.67014 20.0482 3.79868 19.5353L4.77838 15.6258C4.85334 15.3267 4.72371 15.0137 4.45919 14.8552L1.00202 12.7836C0.548437 12.5117 0.548437 11.8545 1.00202 11.5827L4.45919 9.51101C4.72371 9.35249 4.85334 9.03954 4.77838 8.7404L3.79868 4.83094C3.67014 4.31801 4.13491 3.85324 4.64784 3.98178L8.5573 4.96149C8.85643 5.03645 9.16939 4.90682 9.3279 4.64229L11.3996 1.18513Z"
                  fill="white"
                />
              </svg>
              <span>Subscribe our newsletter</span>
            </button>
            <a
              href="https://www.paypal.com/donate/?hosted_button_id=BNJJR5S4YXWEQ"
              className="btn btn--black"
            >
              <svg
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 18.5331L8.55 17.2131C3.4 12.5431 0 9.46311 0 5.68311C0 2.60311 2.42 0.183105 5.5 0.183105C7.24 0.183105 8.91 0.993106 10 2.27311C11.09 0.993106 12.76 0.183105 14.5 0.183105C17.58 0.183105 20 2.60311 20 5.68311C20 9.46311 16.6 12.5431 11.45 17.2231L10 18.5331Z"
                  fill="white"
                />
              </svg>
              <span>Donate</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
