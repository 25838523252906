import React from 'react';

import Author from './Author';
import ShareAndViews from './ShareAndViews';

import formatDate from './formatDate';

export default function Article(props) {

    const formattedDate = formatDate(props.articleInfo.Date);
    if (props.articleInfo.Author == null) {
        props.articleInfo.Author = { "Author": "Anonymous", "Img": "https://i.imgur.com/JKZEBC4.jpg", }
    }

    return (
        <div className="article">
            <div className="article__inner">

                <div className="article__author">
                    {
                        !props.articleInfo.Anon | !props.articleInfo.Author == null ?
                            <Author src={props.articleInfo.Author.Img} name={props.articleInfo.Author.Author} /> 
                            :
                            <div className="author">
                                <div className="author__img__container">
                                    <img src="https://i.imgur.com/JKZEBC4.jpg" className="author__img" alt="Author" />
                                </div>
                                <span className="author__name">Anonymous</span>
                            </div>
                    }
                    <i></i>
                    <span className="article__author__date">{formattedDate}</span>
                </div>

                <a href={`/user/article/${props.articleInfo.id}`} className="article__main">
                    <span className="article__main__left">
                        <h6 className="article__title">{props.articleInfo.Title}</h6>
                        <p>{props.articleInfo.Abstract}</p>
                    </span>
                    {
                        (props.articleInfo.Img !== '' && props.articleInfo.Img !== ' ')
                        &&
                        <span className="article__main__right">
                            <img src={props.articleInfo.Img} alt="Article Img" className="article__image" />
                        </span>
                    }
                </a>

                <div className="article__info">
                    <div className="article__tags">
                        {
                            props.articleInfo.Frontpage &&
                            <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.89543 0 2V24L7.78378 18.9818L15.5676 24V2C15.5676 0.895431 14.6721 0 13.5676 0H2ZM8.23215 3.50309C8.04875 3.13146 7.51882 3.13147 7.33542 3.50309L5.99379 6.22152C5.92096 6.36909 5.78017 6.47138 5.61732 6.49504L2.61735 6.93096C2.20724 6.99056 2.04348 7.49454 2.34024 7.78381L4.51104 9.89981C4.62888 10.0147 4.68266 10.1802 4.65484 10.3424L4.14238 13.3302C4.07233 13.7387 4.50105 14.0502 4.86786 13.8573L7.55111 12.4466C7.69677 12.3701 7.87079 12.3701 8.01645 12.4466L10.6997 13.8573C11.0665 14.0502 11.4952 13.7387 11.4252 13.3302L10.9127 10.3424C10.8849 10.1802 10.9387 10.0147 11.0565 9.89981L13.2273 7.78381C13.5241 7.49454 13.3603 6.99056 12.9502 6.93096L9.95025 6.49504C9.78739 6.47138 9.64661 6.36909 9.57378 6.22152L8.23215 3.50309Z" fill="#AE64AC" />
                            </svg>
                        }
                        {
                            props.articleInfo.Tags.map((tag, index) => (
                                <div className="article__tag" key={index}>{tag}</div>
                            ))
                        }
                    </div>
                    <ShareAndViews 
                        sharedLink={`/user/article/${props.articleInfo.id}`}
                        views={props.articleInfo.Views} 
                        formattedDate={formattedDate} 
                    />
                </div>

            </div>
        </div>
    )
}